import { message } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import { observable, action } from 'mobx';
import type { IObj } from '../../../interface';

class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public errorList: IObj[]; // 错误集合

  @observable public tableHeader = [
    {
      title: '试穿尺码',
      dataIndex: 'clothesSize',
      required: true,
    },
    {
      title: '模特名称',
      dataIndex: 'modelName',
      required: true,
    },
    {
      title: '身高(cm)',
      dataIndex: 'height',
      required: true,
    },
    {
      title: '体重(kg)',
      dataIndex: 'weight',
      required: false,
    },
    {
      title: '试穿心得',
      dataIndex: 'tryOnExperience',
      required: false,
    },
    {
      title: '胸围(cm)',
      dataIndex: 'bust',
      required: false,
    },
    {
      title: '腰围(cm)',
      dataIndex: 'waists',
      required: false,
    },
    {
      title: '臀围(cm)',
      dataIndex: 'hip',
      required: false,
    },
  ];

  @observable public tableList = [
    {
      id: 1,
      clothesSize: 'M',
      modelName: 'MM',
      height: '175',
      weight: '',
      tryOnExperience: '',
      bust: '',
      waists: '',
      hip: '',
    },
    {
      id: 2,
      clothesSize: '',
      modelName: '',
      height: '',
      weight: '',
      tryOnExperience: '',
      bust: '',
      waists: '',
      hip: '',
    },
    {
      id: 3,
      clothesSize: '',
      modelName: '',
      height: '',
      weight: '',
      tryOnExperience: '',
      bust: '',
      waists: '',
      hip: '',
    },
  ];

  @action public findSpecItem = (id) => {
    return this.tableList.find((el) => el.id === id);
  };

  @action public changeValue = (info, value): void => {
    const { type, id } = info;
    this.findSpecItem(id)[type] = value;
  };

  @action public changeInput = (info, e): void => {
    const { type, id } = info;
    this.findSpecItem(id)[type] = e.target.value;

    // .replace(this.parent.tianmaoStore.pattern, '');
  };

  // 校验表格数据
  @action public validTableData = (): any => {
    const tableList = this.tableList;
    const arr = [];
    const allInfo = [];
    for (let i = 0; i < tableList.length; i++) {
      const curData = tableList[i];
      const obj = {
        id: curData.id,
        errorList: [],
      };
      if (!curData.clothesSize && !curData.modelName && !curData.height) {
        allInfo.push(obj);
      }
      if (curData.clothesSize && !curData.modelName && !curData.height) {
        obj.errorList.push('modelName', 'height');
        arr.push(obj);
      } else if (!curData.clothesSize && curData.modelName && !curData.height) {
        obj.errorList.push('clothesSize', 'height');
        arr.push(obj);
      } else if (!curData.clothesSize && !curData.modelName && curData.height) {
        obj.errorList.push('clothesSize', 'modelName');
        arr.push(obj);
      } else if (curData.clothesSize && curData.modelName && !curData.height) {
        obj.errorList.push('height');
        arr.push(obj);
      } else if (curData.clothesSize && !curData.modelName && curData.height) {
        obj.errorList.push('modelName');
        arr.push(obj);
      } else if (!curData.clothesSize && curData.modelName && curData.height) {
        obj.errorList.push('clothesSize');
        arr.push(obj);
      } else if ((curData.weight || curData.tryOnExperience || curData.bust || curData.waists || curData.hip)) {
        if (!curData.clothesSize) {
          obj.errorList.push('clothesSize');
        }
        if (!curData.modelName) {
          obj.errorList.push('modelName');
        }
        if (!curData.height) {
          obj.errorList.push('height');
        }
        if (obj.errorList.length > 0) {
          arr.push(obj);
        }
      }
    }
    if (allInfo.length === 3) {
      message.error('至少填写一行模特试穿信息');
      return [
        {
          id: 1,
          errorList: [
            'clothesSize',
            'modelName',
            'height',
          ],
        },
      ];
    }
    return arr;
  };

  // 回写模特试穿信息 图片和表格
  @action public writeBackModelTryOnVo = (modelTryOnSizeList, modelTryOnPic = '') => {
    const modelTryOnSizeVoList = modelTryOnSizeList || [];
    const tableList = this.tableList;
    if (modelTryOnSizeVoList.length > 0) {
      for (let i = 0; i < tableList.length; i++) {
        tableList[i] = {
          ...tableList[i],
          ...modelTryOnSizeList[i],
        };
      }
      this.tableList = tableList;
      this.parent.tianmaoStore.modelTryOnPic = [
        {
          url: modelTryOnPic,
          uid: this.parent.tianmaoStore.getId(),
        },
      ];
    }
  };

  // 整理table数据
  @action public releaseTableList = () => {
    const tableList = cloneDeep(this.tableList);
    const arr = [];
    for (let i = 0; i < tableList.length; i++) {
      const curData = tableList[i];
      if (curData.clothesSize && curData.modelName && curData.height) {
        delete curData.id;
        arr.push(curData);
      }
    }
    return arr;
  };
}

export default Store;
