import { PlusOutlined, CloseCircleFilled } from '@ant-design/icons';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

interface IUploadTaobao{
  type: string;
  deleteImg: (type, key) => void;
  selectImg: (type, key, size, limitSize) => void;
  fileList: IEachFile[];
  imgSize?: IImgSize;// 图片限制大小
  limitSize?: number;// 图片文件限制大小
}

interface IEachFile{
  key: string;
  url: string;
}

interface IImgSize{
  width?: number;
  height?: number;
  ratio?: number;
}

@observer
class SingleUploadImg extends React.Component<IUploadTaobao> {
  render(): JSX.Element {
    const { selectImg, fileList, deleteImg, type, imgSize, limitSize } = this.props;
    return (
      <div>
        {
          fileList && fileList.length > 0 && fileList[0].url ? (
            <div className={styles.imgWrap}>
              <div className={styles.imgCom}>
                <img
                  alt=""
                  src={fileList[0].url}
                />
              </div>
              <CloseCircleFilled
                className={styles.delete}
                onClick={() => {
                  deleteImg(type, fileList[0]?.key);
                }}
              />
            </div>
          ) : (
            <div
              className={styles.singleUploadImgWrap}
              onClick={() => {
                selectImg(type, fileList[0]?.key, imgSize, limitSize);
              }}
            >
              <div className={styles.plusIcon}>
                <PlusOutlined style={{ fontSize: 20 }}/>
              </div>
              <div>
                上传图片
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

export { SingleUploadImg };

