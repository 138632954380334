import { DeleteOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ModalWrap } from '../generateTagsInfo';
import styles from '../generateTagsInfo/index.less';

const GenerateDurabilityInfo = observer((props) => {
  const {
    visible,
    checkList,
    changeInput,
    changeCheckBox,
    generatePic,
    closeModal,
    removeItem,
    showAllIcons,
    btnLoading,
  } = props.generateDurabilityInfoStore;
  return (
    <ModalWrap
      className={styles.durabilityModalWrapper}
      footer={[
        <Button
          key="close"
          onClick={closeModal}
        >
          取消
        </Button>,
        <Button
          key="submit"
          loading={btnLoading}
          onClick={generatePic}
          type="primary"
        >
          生成
        </Button>,
      ]}
      getContainer={() => document.getElementById('generateDurabilityInfo')}
      mask={false}
      onCancel={closeModal}
      open={visible}
      title="选择标签(最多8项)"

    >
      <div className={styles.contentWrap}>
        <div className={styles.leftLabelWrap}>
          {
            checkList.map((item, index) => {
              return (
                <div
                  className={styles.leftLabel}
                  key={item.id}
                >
                  <div className={styles.labelTitle}>
                    <img
                      className={styles.washIcon}
                      src={item.url}
                    />
                    <span style={{ marginLeft: 8 }}>
                      {item.washIconName}
                    </span>
                  </div>
                  <div>
                    <DeleteOutlined
                      onClick={() => {
                        removeItem(index);
                      }}
                      style={{
                        color: '#6D6E78',
                        fontSize: 13,
                      }}
                    />
                  </div>
                </div>
              );
            })
          }
          {
            checkList.length < 8 && (
              <Button
                className={styles.addBtn}
                onClick={showAllIcons}
              >
                添加
              </Button>
            )
          }
        </div>
        <DurRightInfo checkList={checkList}/>
      </div>
    </ModalWrap>
  );
});

export const ShowAllIconsModal = observer((props) => {
  const {
    visibleIcons,
    checkList,
    allWashIcons,
    generatePic,
    selectedImg,
    closeIconsModal,
  } = props.generateDurabilityInfoStore;
  return (
    <Modal
      className={styles.showIconsModal}
      footer={[
        <Button
          key="close"
          onClick={closeIconsModal}
        >
          取消
        </Button>,
        <Button
          key="submit"
          onClick={closeIconsModal}
          type="primary"
        >
          确定
          {checkList.length}
          /
          8
        </Button>,
      ]}
      onCancel={closeIconsModal}
      open={visibleIcons}
      title="选择标签"
      width={713}
    >
      <div className={styles.showIconsWrap}>
        {
          allWashIcons.map((item) => {
            return (
              <div
                className={styles.imgWrap}
                key={item.id}
              >
                <img
                  className={`${styles.img} ${checkList.find((el) => (el.id === item.id)) ? styles.selectedImg : ''}`}
                  onClick={(e) => {
                    selectedImg(e, item);
                  }}
                  src={item.url}
                />
                <div className={styles.washName}>
                  {item.washIconName}
                </div>
              </div>
            );
          })
        }
      </div>
    </Modal>
  );
});

export const DurRightInfo = observer((props) => (
  <div
    className={styles.rightInfoWrap}
  >
    <div className={styles.bottomWrap}>
      <div className={styles.topWrap}>
        <div className={styles.hegezheng}>
          洗涤说明
        </div>
        {
          props.checkList.map((item) => {
            return (
              <div
                className={styles.labelInfo}
                key={item.id}
              >
                <img
                  className={styles.washIcon}
                  src={item.url}
                  style={{
                    width: 18,
                    height: 18,

                  }}
                />
                <div
                  className={styles.labelTxt}
                  style={{ marginLeft: 8 }}
                >
                  {item.washIconName}
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  </div>
));

export default GenerateDurabilityInfo;

