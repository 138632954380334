
import { message } from 'antd';
import type { FormInstance } from 'antd';
import { request, BaseData } from 'egenie-utils';
import { debounce } from 'lodash';
import { observable, action, toJS } from 'mobx';
import React from 'react';
import { api, scrollToAnchor } from '../../../../utils';

class CategoryPropsStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public goodsId = ''; // 货号

  @observable public brandName = ''; // 品牌名称

  @action public formItemOnChange = (e, info, value, optionObj) => {
    const { type, propsKey, propsName, formRef, isFixedProps, isGoodsProps, index } = info;
    const { fixedPropsValues, otherPropsValues, goodsPropsValues, cascadeProps, fixedProps, otherProps, goodsProps } = this.parent.tmallStepOneStore;
    const { setFieldsValue, getFieldValue, getFieldsValue } = formRef;
    const cascadePropsKeys = Object.keys(cascadeProps);
    const categoryProps = isFixedProps ? fixedProps : otherProps;
    const _categoryProps = toJS(categoryProps);
    let _index = index;
    const propsvalues = isFixedProps ? fixedPropsValues : isGoodsProps ? goodsPropsValues : Object.assign(otherPropsValues);
    if (type === 'select') {
      Object.assign(propsvalues, {
        [propsKey]: {
          preV: propsvalues[propsKey]?.currentV || '',
          currentV: typeof e === 'string' ? e : (e && e.join(',')),
        },
      });
      const propsValueTmp = isFixedProps ? 'fixedPropsValues' : isGoodsProps ? 'goodsPropsValues' : 'otherPropsValues';
      this.parent.tmallStepOneStore[propsValueTmp] = propsvalues;
      const isCascade = cascadePropsKeys.find((item) => item === `${propsKey}_${e}`);
      const isCascadePre = cascadePropsKeys.find((item) => item === `${propsvalues[propsKey]?.preV ? `${propsKey}_${propsvalues[propsKey].preV}` : ''}`);
      _index++;
      if (isCascadePre) {
        _categoryProps.splice(_index, 1);
      }
      if (isCascade) {
        const cascadePropsItem = toJS(cascadeProps[`${propsKey}_${e}`]);
        cascadePropsItem.required = true;
        _categoryProps.splice(_index, 0, cascadePropsItem);
      }
    }
    const categoryPropsTmp = isFixedProps ? 'fixedProps' : isGoodsProps ? 'goodsProps' : 'otherProps';

    this.parent.tmallStepOneStore[categoryPropsTmp] = _categoryProps;
    console.log(_categoryProps, '_categoryProps');
  };

  // 获取类目属性填写信息
  @action public getCategoryProps = (): any => {
    const fixedPropsForm = this.parent.tmallStepOneStore.fixedPropsForm;
    const otherPropsForm = this.parent.tmallStepOneStore.otherPropsForm;
    const values = otherPropsForm.getFieldsValue();
    const obj = {};
    Object.keys(values).map((item) => {
      if (typeof values[item] === 'object') {
        obj[item] = values[item] && values[item].join(',');
      } else {
        obj[item] = values[item];
      }
    });
    const fixedPropsFormData = fixedPropsForm.getFieldsValue();
    this.goodsId = fixedPropsFormData.prop_13021751;

    const item = this.parent.tmallStepOneStore.brandNameLists.find((v) => v.key === fixedPropsFormData.prop_20000);
    this.brandName = item?.value || '';
    return {
      ...fixedPropsFormData,
      ...obj,
    };
  };
}

export { CategoryPropsStore };
