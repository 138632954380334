import { observer } from 'mobx-react';
import React from 'react';
import { getStrLen } from '../../../../utils';
import styles from './index.less';

export const ShowCount = observer((props) => {
  const { value, maxLength, style } = props;
  const valueLenth = getStrLen(value);

  return (
    <div
      className={styles.showCount}
      style={style}
    >
      <span>
        {value ? (valueLenth > maxLength ? maxLength : valueLenth) : 0}
      </span>
      <span>
        /
      </span>
      <span>
        {maxLength}
      </span>
    </div>
  );
});
