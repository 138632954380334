import { message } from 'antd';
import { ContentUtils } from 'braft-utils';
import { action, observable, toJS } from 'mobx';
import { nanoid } from 'nanoid';
import { UploadStore } from '../../../../utils/upLoad/uploadStore';

export class SingleUploadImgStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public type = '';

  @observable public key = '';

  @action public callBack = (params) => {
    if (this.parent.platformType === -100) {
      // 天猫平台
      //  颜色
      const { url } = params[0];

      if (this.type === 'color') {
        const colorIndex = this.parent.tianmaoStore.sizeColorStore.colorList.findIndex((item) => item.name === this.key);
        this.parent.tianmaoStore.sizeColorStore.colorList[colorIndex].url = url;
        return;
      }

      // 模特试穿图
      if (this.type === 'modelTryOnPic') {
        this.parent.tianmaoStore.modelTryOnPic = [
          {
            url,
            uid: nanoid(5),
          },
        ];
      }

      // 长图
      if (this.type === 'fullPics') {
        this.parent.tianmaoStore.verticalImage = [
          {
            url,
            uid: nanoid(5),
            status: 'done',
          },
        ];
        return;
      }

      // 透明图
      if (this.type === 'transPics') {
        this.parent.tianmaoStore.whiteBgImage = [
          {
            url,
            uid: nanoid(5),
            status: 'done',
          },
        ];
      }

      // 资质图片
      if (this.type.includes('qualify')) {
        const qualiType = this.type.slice(7);
        const qualiIndex = this.parent.tianmaoStore.qualificationPics.findIndex((item) => item.type === qualiType);
        this.parent.tianmaoStore.qualificationPics[qualiIndex].fileList = [
          {
            url,
            uid: nanoid(5),
          },
        ];
      }
    } else {
      if (this.type === 'color') {
        // 淘宝 颜色 上传图片
        
        const colorIndex = this.parent.taobaoStore.sizeColorStore.colorList.findIndex((item) => item.name === this.key);
        const { url } = params[0];
        this.parent.taobaoStore.sizeColorStore.colorList[colorIndex].url = url;
      }
  
      if (this.type === 'fullPics') {
        const { url } = params[0];
        this.parent.taobaoStore.fullScreenPics = [
          {
            url,
            key: nanoid(5),
          },
        ];
      }
    }

    // 商品主图
    if (this.type === 'mainPic') {
      // 回写商品主图
      this.parent.mainImgsStore.uploadMainImgsStore.fileLists.push(...params);
    }

    // 富文本
    if (this.type === 'editor') {
      this.parent.editorStore.uploadImgCallback(params);
    }

    // 重置
    this.key = '';
    this.type = '';
  };

  @observable public uploadStoreImg = new UploadStore();// 初始化状态下必须先实例化 否则报错

  // 选择图片
  @action public selectImg = (type, key?, imgSize?, limitSize?): void => {
    this.type = type;
    this.key = key;

    // 主图大小限制
    if (this.type === 'mainPic') {
      limitSize = this.parent.platformType === -100 ? 5 : 3;// 天猫5m 淘宝3m
      imgSize = { ratio: 1 };
    }

    const multipleFlag = type === 'mainPic' || type === 'editor';
    this.uploadStoreImg = new UploadStore({
      type: 'picManage', // picManage 图片 videoManage 视频
      // activeTab: 'manage',
      imgSize,
      limitSize,
      multiple: multipleFlag, // 是否允许多选文件
      limitNumber: (!multipleFlag) ? 1 : 99, // 限制只能上传一个文件或者说只能选择一个文件
      callBack: this.callBack,
    });

    this.uploadStoreImg.onOpen();
  };

  // 删除图片
  @action public deleteImg = (type, key): void => {
    console.log(type, key);

    // 天猫平台
    if (this.parent.platformType === -100) {
      if (type === 'color') {
        // 颜色
  
        const deleteIndex = this.parent.tianmaoStore.sizeColorStore.colorList.findIndex((item) => item.name === key);
        this.parent.tianmaoStore.sizeColorStore.colorList[deleteIndex].url = '';
      }

      if (type === 'modelTryOnPic') {
        // 模特试穿
        this.parent.tianmaoStore.modelTryOnPic = [];
      }

      if (type === 'fullPics') {
        // 商品长图
        this.parent.tianmaoStore.verticalImage = [
          {
            url: '',
            status: 'init',
          },
        ];
      }

      if (type === 'transPics') {
        // 透明素材图
        this.parent.tianmaoStore.whiteBgImage = [
          {
            url: '',
            status: 'init',
          },
        ];
      }

      // 资质图片
      if (type.includes('qualify')) {
        const qualiType = type.slice(7);
        const qualiIndex = this.parent.tianmaoStore.qualificationPics.findIndex((item) => item.type === qualiType);
        this.parent.tianmaoStore.qualificationPics[qualiIndex].fileList = [
          {
            url: '',
            status: 'init',
          },
        ];
      }

      return;
    }

    // 淘宝平台
    if (type === 'color') {
      // 颜色

      const deleteIndex = this.parent.taobaoStore.sizeColorStore.colorList.findIndex((item) => item.name === key);
      this.parent.taobaoStore.sizeColorStore.colorList[deleteIndex].url = '';
    }

    if (type === 'fullPics') {
      // 商品长图
      this.parent.taobaoStore.fullScreenPics = [];
    }
  };
}
