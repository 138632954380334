import { observer } from 'mobx-react';
import { Alert } from 'antd';
import React from 'react';
import MainPictures from './sortLists';
import styles from './index.less';
import UploadMainImgs from './uploadMainImgs';

const MainImgs = observer((props) => {
  const { store, store: { uploadMainImgsStore, uploadMainImgsStore: { fileLists }, onSortEnd, styleImgLists, onPictureClick, clearMainImgs }, singleUploadImgStore } = props;

  const seeNormal = () => {
    window.open('https://helpcenter.taobao.com/servicehall/knowledge_detail?kwdContentId=445704076455375872&searchKey=9fab549735554ba89d1659d54a7a6baf1&source=6&spm=service_hall.25034502.shcSearchResult_kuph4mbg.445704076455375872&hcSessionId=3-1281-30bd039c-6433-4c54-ac29-f5719e3c4ca9');
  };
  return (
    <div className={styles.mainImgsWrap}>
      <Alert
        className={styles.alert}
        message={(
          <span>
            为了进一步提升消费者体验，平台新增
            <a onClick={seeNormal}>
              《商品详情页主图设计规范》
            </a>
            ，请参考该设计规范设计商品主图
          </span>
        )}
        showIcon
        type="warning"
      />
      <div className={styles.tips}>
        建议上传图片比例为1:1，大小
        {store.parent.platformType === -100 ? 5 : 3}
        M以内
      </div>
      <div className={styles.uploadWrap}>
        <UploadMainImgs
          singleUploadImgStore={singleUploadImgStore}
          store={uploadMainImgsStore}
        />
        <div
          className={styles.clearImgs}
          onClick={clearMainImgs}
        >
          清除
        </div>
      </div>
      <div className={styles.allMainImgs}>
        <MainPictures
          axis="xy"
          items={fileLists}
          onSortEnd={onSortEnd}
          store={store}
        />
      </div>
      <div
        className={styles.tips}
        style={{ margin: '8px 0' }}
      >
        款式图片库(可从款式图片库里选择主图)
      </div>
      <div className={styles.styleImgListsWrap}>
        {
          styleImgLists?.length > 0 ? (
            styleImgLists.map((el, index) => {
              const itemIndex = fileLists && fileLists.findIndex((item) => item.url === el);
              return (
                <div
                  className={styles.picture}
                  key={el}
                  onClick={() => onPictureClick(el)}
                >
                  <img
                    alt=""
                    className={styles.img}
                    src={el}
                  />
                  <span
                    className={styles.checkbox}
                    style={fileLists && itemIndex !== -1 ? {
                      border: 'none',
                      backgroundColor: '#1978FF',
                    } : {}}
                  >
                    <div
                      className={styles.isChecked}
                      style={fileLists && itemIndex !== -1 ? {} : { display: 'none' }}
                    />
                  </span>
                </div>
              );
            })
          ) : null
        }
      </div>
    </div>
  );
});

export { MainImgs };

