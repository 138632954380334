import { Col, Form, Input, Select, Spin, Radio, Space } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, formLayout } from '../../../../shelvesBase';
import { MaterialProps } from '../../../components';
import { ShowCount } from '../showCount';
import styles from './index.less';

const { Item } = Form;
const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const BaseInfo = observer((props) => {
  const { store: { hasMaterialProps, baseinfoFormRef, changeInput, formItemOnChange, classificationList, initialValues, initialValues: { goodsName, shortTitle, sellingPoint, cids }}, tmallStepOneStore: { goodsProps }} = props;
  console.log(hasMaterialProps);
  return (
    <ContentWrap
      id="baseinfo"
      text="基本信息"
    >
      <Form
        initialValues={
          initialValues
        }
        ref={baseinfoFormRef}
        {...formLayout}
      >
        <Item
          label="商品标题"
          required
        >
          <div className={styles.itemWrap}>
            <Item
              name="goodsName"
              noStyle
              rules={
                [
                  {
                    required: true,
                    message: '请输入商品标题',
                  },
                ]
              }
            >
              <Input onChange={(e) => {
                changeInput(e, 'goodsName', 60);
              }}
              />
            </Item>
            <ShowCount
              maxLength={60}
              style={{ top: 8 }}
              value={goodsName}
            />
          </div>
        </Item>
        <Item
          label="无线短标题"
          required
        >
          <div className={styles.itemWrap}>
            <Item
              name="shortTitle"
              noStyle
              rules={
                [
                  {
                    required: true,
                    message: '请输入无线短标题',
                  },
                ]
              }
            >
              <Input onChange={(e) => {
                changeInput(e, 'shortTitle', 20);
              }}
              />
            </Item>
            <ShowCount
              maxLength={20}
              style={{ top: 8 }}
              value={shortTitle}
            />
          </div>
        </Item>
        <Item label="商品卖点">
          <div className={styles.itemWrap}>
            <Item
              name="sellingPoint"
              noStyle
              rules={
                [{ required: false }]
              }
            >
              <Input onChange={(e) => {
                changeInput(e, 'sellingPoint', 40);
              }}
              />
            </Item>
            <ShowCount
              maxLength={40}
              style={{ top: 8 }}
              value={sellingPoint}
            />
          </div>
        </Item>
       
        {
          goodsProps && goodsProps.length > 0 && (
            <Item label="商品属性">
              <div className={`${styles.itemWrap} ${styles.itemBack}`}>
                {hasMaterialProps && props.materialStore?.materialDict && props.materialStore?.materialDict.length > 0 && (
                  <MaterialProps
                    inputNumberCustom
                    store={props.materialStore}
                  />
                )}
                {goodsProps && goodsProps.map((item, index) => {
                  // ... 在此处不同布局的判断
                  const key = item.id;
                  return (
                    <Col
                      className={item.className || ''}
                      key={key}
                      span={item.colspan || 12}
                    >
                      <Item
                        {...(item.itemLayout || formItemLayout)}
                        initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}
                        label={item.name}
                        name={key}
                        normalize={(value, prevValue, allValues) => {
                          if (item.type === 'MULTICHECK') {
                            if (typeof value === 'string') {
                              return value.split(',');
                            }
                          }
                          return value;
                        }}
                        rules={[
                          item.rules || {
                            required: item.required,
                            message: item.message,
                          },
                        ]}
                      >
                        {item.type === 'INPUT' ? (
                          <Input
                            autoComplete="off"
                            className={item.className || ''}
                            id={key}
                            onChange={(e) => {
                              formItemOnChange(e, {
                                type: 'input',
                                key,
                                index,
                              });
                            }}
                            placeholder={item.placeholder || '请输入'}
                          />
                        ) : (
                          <Select
                            allowClear={item.type !== 'INPUT'}
                            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                            notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                            onChange={(e) => {
                              formItemOnChange(e, {
                                type: 'select',
                                propsKey: key,
                                index,
                              });
                            }}
                            placeholder="请选择"
                            showSearch
                          >
                            {item.checkOptions && item.checkOptions.map((v) => {
                              return (
                                <Option
                                  key={v.key}
                                  value={v.key}
                                >
                                  {v.value}
                                </Option>
                              );
                            })}
                          </Select>
                        )}
                      </Item>
                    </Col>
                  );
                })}
              </div>
            </Item>
          )
        }
        {
          classificationList && (
            <Item
              label="店铺分类"
              name="cids"
              normalize={(value, prevValue, allValues) => {
                if (typeof value === 'string') {
                  return value.split(',');
                }
                return value;
              }}
            >
              <Select
                allowClear
                mode="multiple"
  
                // onChange={(value) => {
                //   handleChangeSelect(value, 'cids');
                // }}
                style={{ width: 330 }}
              >
                {
                  classificationList && classificationList.map((item) => (
                    <Select.Option
                      disabled={cids && cids.length > 9 && cids.filter((el) => el == item.value).length === 0}
                      key={item.value}
                      value={item.value}
                    >
                      {item.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </Item>
          )
        }
      
        <Item
          label="是否申报新品"
          name="xinpin"
          rules={
            [
              {
                required: true,
                message: '请选择是否申报新品',
              },
            ]
          }
        >
          <Radio.Group>
            <Space>
              <Radio
                value="true"
              >
                申报
              </Radio>
              <Radio
                value="false"
              >
                不申报
              </Radio>
            </Space>
          </Radio.Group>
        </Item>
      </Form>
    </ContentWrap>
  );
});

export default BaseInfo;
