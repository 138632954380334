import { Radio, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap } from '../../../../shelvesBase';
import { SingleUploadImg } from '../../../components';
import styles from '../index.less';
import TableInfo from '../tableInfo';

export const SizeInfo = observer((props: { tianmaoStore; singleUploadImgStore;tableStore; }) => {
  const { tianmaoStore, singleUploadImgStore, tableStore } = props;
  const { sizeMap, sizeChartTemplate, sizeMeasureImageLists, sizeMeasureImage, onCkeckDiagram, changeSize, modelTryOnPic } = tianmaoStore;
  const { selectImg, deleteImg } = singleUploadImgStore;
  return (
    <ContentWrap
      id="sizeInfo"
      text="尺码信息"
    >
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          设置尺码表：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <Radio.Group
            onChange={changeSize}
            value={sizeChartTemplate}
            
          >
            <Radio
              style={{ marginBottom: 16 }}
              value=""
            >
              无
            </Radio>
            {
              sizeMap && sizeMap.map((el) => {
                return (
                  <Radio
                    key={el.templateId}
                    style={{ marginBottom: 16 }}
                    value={el.templateId}
                  >
                    {el.templateName}
                  </Radio>
                );
              })
            }
          </Radio.Group>
        </Col>
      </Row>
      {sizeMeasureImageLists && sizeMeasureImageLists.length > 0 && (
        <Row>
          <Col
            className={styles.colTitle}
            id="dimensioningDiagram"
            span={3}
          >
            <span className={styles.red}>
              *
            </span>
            尺码测量示意图：
          </Col>
          <Col
            className={styles.colContent}
            span={21}
          >
            <div className={styles.diagramWrap}>
              {
                sizeMeasureImageLists.map((item) => {
                  return (
                    <div
                      className={styles.dimensioningDiagramImgWrap}
                      key={item.key}
                      onClick={() => {
                        onCkeckDiagram(item.key);
                      }}
                      style={{ borderColor: sizeMeasureImage === item.key ? '#1978ff' : 'rgba(0, 0, 0, 0.04)' }}
                    >
                      <img
                        alt=""
                        className={styles.dimensioningDiagramImg}
                        src={item.key}
                      />
                      <div
                        className={styles.sector}
                        style={sizeMeasureImage === item.key ? {} : { display: 'none' }}
                      >
                        <div
                          className={styles.isChecked}
                        />
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          模特试穿信息图：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <SingleUploadImg
            deleteImg={() => {
              deleteImg('modelTryOnPic');
            }}
            fileList={modelTryOnPic}
            selectImg={selectImg}
            type="modelTryOnPic"
          />
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          模特试穿信息：
        </Col>
      </Row>
      <div
        className={styles.colContent}
      >
        <TableInfo tableStore={tableStore}/>
      </div>
    </ContentWrap>
  );
});

