import { observer } from 'mobx-react';
import React from 'react';
import ImgOrVideoUpload from '../../../../utils/upLoad';
import { ProfitInput, FootPart, AnchorCustom } from '../../../shelvesBase';
import { AfterSaleInfo } from './afterSaleInfo';
import BaseinfoForm from './baseinfo';
import styles from './index.less';
import { LogisticInfo } from './logisticInfo';
import { ProductDescription } from './productDesc';
import { SaleInfo } from './saleInfo';
import { SizeInfo } from './sizeInfo';

const TianmaoInfo = observer((props: { store; tianmaoStore;generateTagsInfoStore;
  generateDurabilityInfoStore; tableStore;editorStore;mainImgsStore;baseinfoStore;categoryPropsStore;anchorStore;tmallStepOneStore;singleUploadImgStore; materialStore; }) => {
  const { singleUploadImgStore } = props.store;
  const { submitInfo, saveInfo, sizeColorStore } = props.tianmaoStore;
  return (
    <>
      <div className={styles.tianmaoWrap}>
        <AnchorCustom store={props.anchorStore}/>
        <BaseinfoForm
          materialStore={props.materialStore}
          store={props.baseinfoStore}
          tmallStepOneStore={props.tmallStepOneStore}
        />
        {/* 销售信息 */}
        
        <SaleInfo
          singleUploadImgStore={singleUploadImgStore}
          tianmaoStore={props.tianmaoStore}
        />
        {/* 尺码信息 */}
        <SizeInfo
          singleUploadImgStore={props.singleUploadImgStore}
          tableStore={props.tableStore}
          tianmaoStore={props.tianmaoStore}
        />
        {/* 商品描述 */}
        <ProductDescription
          editorStore={props.editorStore}
          generateDurabilityInfoStore={props.generateDurabilityInfoStore}
          generateTagsInfoStore={props.generateTagsInfoStore}
          mainImgsStore={props.mainImgsStore}
          singleUploadImgStore={props.singleUploadImgStore}
          store={props.store}
          tianmaoStore={props.tianmaoStore}
        />
        {/* 物流信息 */}
        <LogisticInfo tianmaoStore={props.tianmaoStore}/>
        {/* 售后信息 */}
        <AfterSaleInfo
          store={props.store}
          tianmaoStore={props.tianmaoStore}
        />
      </div>
      <FootPart
        save={saveInfo}
        submitInfo={submitInfo}
      >
        <ProfitInput handleProfitAdd={sizeColorStore.handleSalePriceAddProfit}/>
      </FootPart>
      <ImgOrVideoUpload store={singleUploadImgStore.uploadStoreImg}/>
    </>
  );
});

export default TianmaoInfo;

