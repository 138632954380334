import { Form, Row, Col, Radio, Checkbox, Space, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, formLayout } from '../../../../shelvesBase';
import styles from '../index.less';

const { Item } = Form;

export const AfterSaleInfo = observer((props: { tianmaoStore;store; }) => {
  const { tianmaoFormRef } = props.store;
  const { invoice, warrantyService, memberDiscount, stockCountMode, rebateRatio, listingTimeMode } = props.tianmaoStore;
  return (
    <ContentWrap
      id="afterSaleAndOther"
      text="售后及其他"
    >
      <Form
        className={styles.formWrap}
        initialValues={
          {
            invoice,
            warrantyService,
            memberDiscount,
            stockCountMode,
            rebateRatio,
            listingTimeMode,
          }
        }
        ref={tianmaoFormRef}
        {...formLayout}
      >
        <Item
          label="发票"
          name="invoice"
          valuePropName="checked"
        >
          <Checkbox
            checked
            disabled
          />
        </Item>
        <Item
          label="售后服务"
          name="warrantyService"
        >
          <Checkbox.Group className={styles.itemBackground}>
            <Space direction="vertical">
              <Checkbox
                className={styles.strategyCheckbox}
                value="1"
              >
                保修服务
              </Checkbox>
              <Checkbox
                className={styles.strategyCheckbox}
                value="2"
              >
                <span>
                  退换货承诺
                </span>
                <span
                  className={styles.tipsTxt}
                  style={{ marginLeft: 16 }}
                >
                  凡使用支付宝服务付款购买本店商品，若存在质量问题或与描述不符，本店将主动提供退换货服务并承担来回邮费
                </span>
              </Checkbox>
            </Space>
          </Checkbox.Group>
        </Item>
        <Item
          label="会员让利"
          name="memberDiscount"
        >
          <Radio.Group>
            <Space>
              <Radio
                value="false"
              >
                不参与会员让利
              </Radio>
              <Radio
                value="true"
              >
                参与会员让利
              </Radio>
            </Space>
          </Radio.Group>
        </Item>
        <Item
          label="拍下减库存"
          name="stockCountMode"
        >
          <Radio.Group>
            <Space>
              <Radio
                value="true"
              >
                是
              </Radio>
              <Radio
                value="false"
              >
                否
              </Radio>
            </Space>
          </Radio.Group>
        </Item>
        <Row>
          <Col span={3}/>
          <Col
            span={21}
            style={{
              position: 'relative',
              top: '-25px',
            }}
          >
            <div className={styles.tipsTxt}>
              秒杀商品或超低价抢购促销商品或热卖商品请选择拍下减库存，可以防止超卖情况！
            </div>
          </Col>
        </Row>
        <Form.Item
          label="返点比例"
          required
        >
          <Item
            label="返点比例"
            name="rebateRatio"
            noStyle
            rules={
              [
                {
                  required: true,
                  message: '请输入返点比例',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const reg = /^[1-9]\d*\.[5]$|0\.[5]$|^[1-9]\d*$/;
                    if (!value && value !== 0) {
                      return Promise.resolve();
                    }
                    if (!reg.test(value) || Number(value) > 1.5 || value == 0) {
                      return Promise.reject('返点比例必须大于0且是0.5%的整数倍且不能高于1.5%');
                    }
                    return Promise.resolve();
                  },
                }),
              ]
            }
          >
            <InputNumber
              min={0.5}
              style={{ width: 88 }}
            />
          </Item>
          %
          <div
            className={styles.tipsTxt}
            style={{ marginTop: 8 }}
          >
            该类商品的最低返点比例为0.5%,设定的返点比例必须是0.5%的整数倍,且不能高于1.5%
          </div>
        </Form.Item>
        <Item
          label="上架时间"
          name="listingTimeMode"
        >
          <Radio.Group>
            <Space>
              <Radio
                value="0"
              >
                立刻上架
              </Radio>
              <Radio
                value="2"
              >
                放入仓库
              </Radio>
            </Space>
          </Radio.Group>
        </Item>
      </Form>
    </ContentWrap>
  );
});

