import { message, Modal } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { action, toJS, observable } from 'mobx';
import React from 'react';
import shortid from 'shortid';
import { api } from '../../../../../utils';
import type { IFiles } from '../../../interface';

export class UploadMainImgsStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public fileLists: IFiles[] = [];

  @action public beforeUpload = (file: File): boolean => {
    this.checkPic(file) && this.uploadImg(file);
    return true;
  };

  @action public checkPic = (file: File): boolean => {
    const mimeType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/heic';
    if (!mimeType) {
      message.error('请上传jpg或png格式图片！');
      return false;
    }
    if (file.size > 5 * 1024 * 1024) {
      message.error('图片文件大小超过限制');
      return false;
    }
    if (file.name.indexOf(',') !== -1 || file.name.indexOf('，') !== -1) {
      message.error('图片名称不能包含逗号');
      return false;
    }
    if (file.name.indexOf(' ') !== -1) {
      message.error('图片名称不能包含空格');
      return false;
    }
    return true;
  };

  @action public uploadImg = (file: File): void => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleType', 'pc');
    formData.append('title', 'url');
    request<BaseData<any>>({
      url: api.uploadFile,
      method: 'post',
      data: formData,
    }).then((res) => {
      console.log('res');
      this.fileLists = [
        ...this.fileLists,
        {
          url: res.data,
          uid: shortid.generate(),
        },
      ];
      if (this.parent.uploadImgCallback) {
        this.parent.uploadImgCallback();
      }
      console.log(toJS(this.fileLists), 'this.fileLists');
    });
  };
}
