import { DatePicker, Input, InputNumber } from 'antd';
import { Observer } from 'mobx-react-lite';
import React from 'react';
import styles from './index.less';

// 商品规格表格列
export const saleSpecColumns = (context) => {
  const { colorPropName, sizePropName } = context;
  return [
    {
      name: '颜色',
      key: 'color',
    },
    {
      name: '尺码',
      key: 'size',
    },
    {
      name: '进货价',
      key: 'costPrice',
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridInputWrapper}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  售价
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={1}
                    onBlur={() => {
                      context.handleAllSalePriceBlur();
                    }}
                    onChange={(price) => {
                      context.handleAllSalePriceChange(price);
                    }}
                    onPressEnter={() => {
                      context.handleAllSalePriceBlur();
                    }}
                    placeholder="批量填写"
                    precision={2}
                    size="small"
                    value={context.allPrice}
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 160,
      key: 'salePrice',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={0}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
                context.handleSingleSalePriceChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={2}
              size="small"
              value={row.salePrice}
            />
          </div>
        );
      },
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridHeader}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  数量
                  <InputNumber
                    className={`${styles.gridHeaderInput} ${styles.gridInput}`}
                    max={999999}
                    min={0}
                    onBlur={() => {
                      context.handleAllNumBlur();
                    }}
                    onChange={(val) => {
                      context.handleAllNumChange(val);
                    }}
                    onPressEnter={() => {
                      context.handleAllNumBlur();
                    }}
                    placeholder="批量填写"
                    precision={0}
                    size="small"
                    value={context.allNum}
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 160,
      key: 'stockNum',
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <InputNumber
              max={999999}
              min={0}
              onChange={(price) => {
                const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
                context.handleSingleNumChange(goodsSpec, price);
              }}
              onClick={(e) => e.stopPropagation()}
              precision={0}
              size="small"
              value={row.stockNum}
            />
          </div>
        );
      },
    },
    {
      name: (
        <Observer>
          {
            () => {
              return (
                <div className={styles.gridHeader}>
                  <span className={styles.requiredTip}>
                    *
                  </span>
                  上市时间
                  <DatePicker
                    className={styles.dateWrapper}
                    onChange={(moment) => {
                      context.handleAllSkuMarketTimeChange(moment);
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    size="small"
                  />
                </div>
              );
            }
          }
        </Observer>
      ),
      width: 220,
      key: 'skuMarketTime',
      ejlHidden: true,
      formatter: ({ row }) => {
        return (
          <div className={styles.gridInputWrapper}>
            <DatePicker
              onChange={(moment) => {
                context.handleSingleSkuMarketTimeChange(row.goodsSpec, moment);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
              size="small"
              value={row?.skuMarketTime || ''}
            />
          </div>
        );
      },
    },
    {
      name: 'SKU编码',
      key: 'goodsSkuNo',
      width: 220,
      formatter: ({ row }) => {
        return (
          <Input
            defaultValue={row.goodsSkuNo}
            onBlur={(e) => {
              const goodsSpec = assembleProperty(colorPropName, row.color, sizePropName, row.size);
              context.handleSkuChange(goodsSpec, e.target.value);
            }}
            onClick={(e) => e.stopPropagation()}
          />
        );
      },
    },
  ];
};

const assembleProperty = (colorPropName, color, sizePropName, size) => {
  return `${colorPropName}:${color};${sizePropName}:${ size}`;
};
