import { CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ShowCount } from '../showCount';
import styles from './index.less';

const { TextArea } = Input;

const GenerateTagsInfo = observer((props) => {
  const {
    visible,
    tags,
    checkList,
    checkedValues,
    changeInput,
    changeCheckBox,
    generatePic,
    closeModal,
    tagMap,
    btnLoading,
    generateTagsRef,
  } = props.generateTagsInfoStore;
  return (
    <ModalWrap
      className={styles.tagsModalWrapper}
      footer={[
        <Button
          key="close"
          onClick={closeModal}
        >
          取消
        </Button>,
        <Button
          key="submit"
          loading={btnLoading}
          onClick={() => {
            generatePic('tags', checkList.filter((item) => item.isShow), '请勾选吊牌信息', 'generateTagsCanvas', 0);
          }}
          type="primary"
        >
          生成
        </Button>,
      ]}
      getContainer={() => document.getElementById('generateTags')}
      mask={false}
      onCancel={closeModal}
      open={visible}
      title="选择吊牌信息"
    >
      <div className={styles.contentWrap}>
        <div className={styles.leftLabelWrap}>
          <div className={styles.leftLabel}>
            <Checkbox.Group
              onChange={changeCheckBox}
              style={{ width: '100%' }}
              value={checkedValues}
            >
              {
                tags.map((item) => {
                  return (
                    <Row key={item.type}>
                      <Col
                        className={styles.colLabel}
                        span={24}
                      >
                        <Checkbox value={item.type}>
                          {item.label}
                        </Checkbox>
                        {
                          item.type !== 'composition' && (
                            <Input
                              maxLength={10}
                              onChange={(e) => {
                                changeInput(e, item.type);
                              }}
                              style={{
                                width: 200,
                                marginLeft: 20,
                              }}
                              value={tagMap[item.type]}
                            />
                          )
                        }
                        {
                          item.type == 'composition' && (
                            <TextArea
                              autoSize
                              onChange={(e) => {
                                changeInput(e, item.type);
                              }}
                              style={{
                                width: 200,
                                marginLeft: 20,
                              }}
                              value={tagMap[item.type]}
                            />
                          )
                        }
                        {
                          item.type !== 'composition' && (
                            <ShowCount
                              maxLength={10}
                              value={tagMap[item.type]}
                            />
                          )
                        }
                      </Col>
                    </Row>
                  );
                })
              }
            </Checkbox.Group>
          </div>
        </div>
        <TagsRightInfo checkList={checkList}/>
      </div>
    </ModalWrap>
  );
});
export const ModalWrap = observer((props) => {
  const {
    type,
    title,
    visible,
    onCancel,
    canvasId,
    checkList,
  } = props;
  return visible ? (
    <div className={`${styles.customModalWrap} ${props.className}`}>
      <div className={styles.triangle}>
        <span className={styles.triangleContent}/>
      </div>
      <div className={styles.header}>
        {title}
        <CloseOutlined onClick={onCancel}/>
      </div>
      <div className={styles.body}>
        {props.children}
      </div>
      <div className={styles.footer}>
        {
          props.footer.map((item) => (
            item
          ))
        }
      </div>
    </div>
  ) : null;
});

export const TagsRightInfo = observer((props) => {
  const { checkList } = props;
  return (
    <div
      className={styles.rightInfoWrap}
    >
      <div className={styles.bottomWrap}>
        <div className={styles.topWrap}>
          <div className={styles.hegezheng}>
            合格证
          </div>
          {
            checkList.map((item, index) => {
              return item.isShow ? (
                <div
                  className={styles.labelInfo}
                  key={item.type}
                >
                  <div
                    className={`${styles.labelTxt} ${styles.labelTitle}`}
                    style={{ width: index < 5 ? 36 : 70 }}
                  >
                    {item.label}
                    ：
                  </div>
                  <div
                    className={`${styles.labelTxt} ${styles.labelTxtInfo}`}
                  >
                    {item.value}
                  </div>
                </div>
              ) : null;
            })
          }
        </div>
      </div>
    </div>
  );
});

export default GenerateTagsInfo;

