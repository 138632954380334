import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Form, Input, Popover, Row, Select, Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { MaterialProps } from '../index';
import styles from './index.less';

const { Option } = Select;
const FormItem = Form.Item;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

@observer
export class CategoryProps extends Component<{ formRef;setFormRef?; categoryPropsStore?; isFixedProps?; isGoodsProps?; tmallStepOneStore?; propsValues?;categoryProps?;materialStore?; }> {
  constructor(props) {
    super(props);
  }

  render() {
    const { formRef, setFormRef, categoryPropsStore, isFixedProps, isGoodsProps, propsValues, categoryProps, tmallStepOneStore, materialStore } = this.props;
    
    return (
      <div>
        <Form

          // initialValues={propsValues}
          ref={setFormRef}
        >
         
          <FormRow
            categoryProps={categoryProps}
            formRef={formRef}
            isFixedProps={isFixedProps}
            isGoodsProps={isGoodsProps}
            materialStore={materialStore}
            propsValues={propsValues}
            store={categoryPropsStore}
            tmallStepOneStore={tmallStepOneStore}
          />
        </Form>
      </div>
    );
  }
}

const PropsFormWrap = observer((props) => {
  const { setFormRef, isFixedProps, isGoodsProps, store, formRef, tmallStepOneStore, propsValues } = props;
  console.log(propsValues, 'propsValues');
  return (
    <Form
      ref={setFormRef}
    >
      <FormRow
        formRef={formRef}
        isFixedProps={isFixedProps}
        isGoodsProps={isGoodsProps}
        propsValues={propsValues}
        store={store}
        tmallStepOneStore={tmallStepOneStore}
      />
    </Form>
  );
});

const FormRow = observer((props) => {
  const {
    store: { formItemOnChange },
    tmallStepOneStore: { fixedProps, otherProps },
    categoryProps,
    isGoodsProps,
    isFixedProps,
    formRef,
    materialStore,
  } = props;
  const categoryPropsCus = isFixedProps ? fixedProps : otherProps;
  return (
    <Row>
      {props.materialStore?.materialDict && props.materialStore?.materialDict.length > 0 && props.materialStore?.isMaterialRequired && (
        <MaterialProps
          inputNumberCustom
          store={props.materialStore}
        />
      )}
      {categoryPropsCus && categoryPropsCus.map((item, index) => {
        const key = item.id;
        return (
          <Col
            className={item.className || ''}
            key={key}
            span={item.colspan || 12}
          >
            {(
              <FormItem
                {...(item.itemLayout || formItemLayout)}

                initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}
                label={item.tips || item.name === '货号' ? renderLabel({
                  name: item.name,
                  tips: item.tips,
                }) : item.name}
                name={key}
                normalize={(value, prevValue, allValues) => {
                  if (item.type === 'MULTICHECK') {
                    // 传给后端是逗号分隔字符串，返回数据也得做还原
                    if (typeof value === 'string') {
                      return value.split(',');
                    }
                  }
                  return value;
                }}

                rules={[
                  item.rules || {
                    required: item.required,
                    message: item.message || (item.type === 'INPUT' ? `请输入${item.name}` : `请选择${item.name}`),
                  },
                ]}
              >
                {item.type === 'INPUT' ? (
                  <Input
                    autoComplete="off"
                    className={item.className || ''}
                    id={key}
                    onChange={(e) => {
                      formItemOnChange(e, {
                        type: 'input',
                        propsKey: key,
                        propsName: item.name,
                        formRef,
                        categoryProps,
                        isFixedProps,
                        isGoodsProps,
                        index,
                      });
                    }}
                    placeholder={item.placeholder || '请输入'}
                  />
                ) : (
                  <Select
                    allowClear={item.type !== 'INPUT'}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                    notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}
                    onChange={(e) => {
                      formItemOnChange(e, {
                        type: 'select',
                        propsKey: key,
                        propsName: item.name,
                        formRef,
                        categoryProps,
                        isFixedProps,
                        isGoodsProps,
                        index,
                      });
                    }}
                    placeholder={item.type === 'INPUT' ? '请输入' : '请选择'}
                    showSearch
                  >
                    {item.checkOptions && item.checkOptions.map((v) => {
                      return (
                        <Option
                          key={v.key}
                          value={v.key}
                        >
                          {v.value}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </FormItem>
            )}
          </Col>
        );
      })}
    </Row>
  );
});

export const renderLabel = (props) => {
  const { name, tips = []} = props;
  const sellNoTip = [
    '货号是指能定位到唯一一款产品的编号',
    '请完全按照吊牌、耐久性标签上的货号信息填写',
    '该属性为天猫管控属性，提交后48小时不可以编辑，请如实填写',
  ];
  const tipArr = name === '货号' ? sellNoTip : tips;
  return (
    <Popover
      content={(
        <div className={styles.propsTip}>
          {
            tipArr.map((item, index) => {
              let indx = index;
              indx++;
              return (
                <div key={item}>
                  {indx}
                  .
                  {item}
                </div>
              );
            })
          }
        </div>
      )}
    >
      <span style={{ cursor: 'pointer' }}>
        {name}
        <QuestionCircleOutlined/>
      </span>
    </Popover>
  );
};
