
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import { observable, action } from 'mobx';
import { CategorySelectorStore } from '../../../shelvesBase';

class Store {
  constructor(options) {
    this.parent = options;
  }

  public categoryIds = '';

  public categoryId = 0;

  public categoryName = '';

  @observable public parent;

  @observable public categoryStore = new CategorySelectorStore();

  // 下一步
  @action public onNext = async() => {
    const categoryIds = this.categoryStore.selectedValue;
    const categoryId = categoryIds[categoryIds.length - 1];

    // 将类目id和内容存入
    this.categoryId = categoryId;
    this.categoryIds = categoryIds.join(',');
    this.categoryName = this.categoryStore.selectTxt.replace(/>/g, ',');
    const params = {
      gmsGoodsId: this.parent.gmsGoodsId,
      categoryId,
    };
  
    this.parent.loading = true;

    try {
      // 检查是否修改已发布商品的类目
      await this.checkCategoryChanged(params);
      await this.parent.taobaoStore.getTaobaoInfo(params);
    } catch (e) {
      console.log(e);
      this.parent.loading = false;
    }
  };

  // 检查是否修改已发布商品的类目
  public checkCategoryChanged = (params) => {
    return request<BaseData>({
      url: '/api/gms/goods/platform/taobao/checkCategoryChanged',
      method: 'POST',
      data: params,
    });
  };
}

export default Store;
