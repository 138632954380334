import { Form, Input, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap } from '../../../../shelvesBase';
import { SizeAndColorInfo } from '../../../components';
import styles from '../index.less';

const { Item } = Form;

export const SaleInfo = observer((props: { tianmaoStore; singleUploadImgStore; }) => {
  const { tianmaoStore, singleUploadImgStore } = props;
  const { saleInfoRef } = tianmaoStore;
  return (
    <ContentWrap
      id="saleInfo"
      text="销售信息"
    >
      <Form
        labelCol={{ span: 3 }}
        ref={saleInfoRef}
        wrapperCol={{ span: 21 }}
      >
        {/* 颜色尺码信息 */}
        <SizeAndColorInfo
          singleUploadImgStore={singleUploadImgStore}
          sizeColorStore={tianmaoStore.sizeColorStore}
        />
        <Item
          label="一口价"
          required
          wrapperCol={{ span: 6 }}
        >
          <div className={styles.specFormItem}>
            <Item
              name="salePrice"
              rules={[
                {
                  required: true,
                  message: '请输入一口价',
                },
              ]}
              style={{ marginBottom: 0 }}
            >
              <InputNumber
                disabled
                style={{ width: '100%' }}
              />
            </Item>
            <span>
              元
            </span>
          </div>
        </Item>
        <Item
          label="总数量"
          required
          wrapperCol={{ span: 6 }}
        >
          <div className={styles.specFormItem}>
            <Item
              name="stockNum"
              required
              style={{ marginBottom: 0 }}
            >
              <Input disabled/>
            </Item>
            <span>
              件
            </span>
          </div>
        </Item>
        <Item
          label="商家编码"
          name="goodsCode"
          wrapperCol={{ span: 5 }}
        >
          <Input/>
        </Item>
      </Form>
    </ContentWrap>
  );
});

