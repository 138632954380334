import { CloseCircleFilled } from '@ant-design/icons';
import arrayMove from 'array-move';
import { observer } from 'mobx-react';
import React, { Component, ReactNode, useState, useRef } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import deleteIcon from '../../../../assets/images/deleteIcon.png';
import styles from './index.less';

// 图片展示卡片

const SortableItem = SortableElement(({ src, sortIndex, store }) => {
  const { onClickDelete } = store;
  return (
    <div
      className="publishGoodsPicture"
      style={{ marginBottom: 12 }}
    >
      <img
        alt=""
        className="img"
        src={src}
      />
      <img
        className="delete"
        onMouseDown={(e) => {
          onClickDelete(src);
          e.stopPropagation();
        }}
        src={deleteIcon}
      />
      {
        sortIndex < 5 ? (
          <span className="coverIcon">
            主图
          </span>
        ) : ''
      }
    </div>
  );
});

const SortableList = SortableContainer(observer(({ items, store }) => {
  return (
    <div className={styles.mainPictures}>
      {items && items.map((el, index) => {
        return el && el.url && (
          <SortableItem
            index={index}
            key={`item-${el.url}`}
            sortIndex={index}
            src={el.url}
            store={store}
          />
        );
      }
      )}
    </div>
  );
}));

export default SortableList;
