import { Affix, Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { CategorySelector } from '../../../shelvesBase';
import { CategoryProps, MaterialProps } from '../../components';
import styles from './index.less';
import type TmallStepOnetore from './store';

export const TmallStepOne = observer((props: { tmallStepOneStore;categoryPropsStore;materialStore; }) => {
  const { categoryStore, fixedProps } = props.tmallStepOneStore;
  return (
    <div
      className={styles.tmallStepOneWrap}
    >
      {/* 选择类目 */}
      <CategorySelector store={categoryStore}/>
      {/* 类目属性 */}
      <Row

        // @ts-ignore
        style={{ visibility: `${fixedProps?.length > 0 ? 'visible' : 'hidden'}` }}
      >
        <CategoryPropPart
          categoryPropsStore={props.categoryPropsStore}
          materialStore={props.materialStore}
          tmallStepOneStore={props.tmallStepOneStore}
        />
      </Row>

      {/* 底部按钮 */}
      {
        fixedProps?.length && (
          <FirstStepFooter store={props.tmallStepOneStore}/>
        )
      }
    </div>
  );
});

// 类目属性
const CategoryPropPart: React.FC<{ categoryPropsStore; tmallStepOneStore; materialStore; }> = (props) => {
  const { setFixedFormRef, setOtherFormRef, fixedPropsForm, otherPropsForm, fixedPropsValues, otherPropsValues, fixedProps, otherProps } = props.tmallStepOneStore;
  const { materialDict, isMaterialRequired } = props.materialStore;
  return (
    <>
      <Col
        className={styles.colTitle}
        span={4}
      />
      <Col
        className={`${styles.colTitle} ${styles.colValue}`}
        span={20}
        style={{ minHeight: 60 }}
      >
        <div className={styles.allCategoryWrap}>
          <CategoryProps
            categoryProps={fixedProps}
            categoryPropsStore={props.categoryPropsStore}
            formRef={fixedPropsForm}
            isFixedProps
            propsValues={fixedPropsValues}
            setFormRef={setFixedFormRef}
            tmallStepOneStore={props.tmallStepOneStore}
          />
        </div>
      </Col>
      <Col
        className={styles.colTitle}
        span={4}
        style={{ marginTop: 20 }}
      >
        类目属性：
      </Col>
      <Col
        className={`${styles.colTitle} ${styles.colValue}`}
        span={20}
      >
        <div
          className={styles.allCategoryWrap}
          style={{ minHeight: 200 }}
        >
          <CategoryProps
            categoryProps={otherProps}
            categoryPropsStore={props.categoryPropsStore}
            formRef={otherPropsForm}
            materialStore={props.materialStore}
            propsValues={otherPropsValues}
            setFormRef={setOtherFormRef}
            tmallStepOneStore={props.tmallStepOneStore}
          />
          {materialDict && materialDict.length > 0 && !isMaterialRequired && (
            <MaterialProps
              inputNumberCustom
              store={props.materialStore}
            />
          )}
        </div>
      </Col>
    </>
  );
};

// 底部按钮
const FirstStepFooter: React.FC<{ store: TmallStepOnetore; }> = (props) => {
  const { onSave, submitloading, saveloading } = props.store;
  return (
    <Affix offsetBottom={0}>
      <div className={styles.footerWrap}>
        <Row>
          <Col
            className={styles.Wrap}
            span={4}
          />
          <Col
            className={styles.footer}
            span={20}
          >
            <Button
              loading={submitloading}
              onClick={() => {
                onSave('next');
              }}
              type="primary"
            >
              下一步
            </Button>
            <Button
              className={styles.editProductBtn}
              loading={saveloading}
              onClick={() => {
                onSave('save');
              }}
              style={{ marginLeft: 12 }}
            >
              保存
            </Button>
          </Col>
        </Row>
      </div>
    </Affix>
  );
};
