import { message, Modal } from 'antd';
import type { FormInstance } from 'antd';
import { request } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import React from 'react';

import { api, scrollToAnchor, getStr, getStrLen } from '../../../../../utils';

export default class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  public hasMaterialProps = false;// 第二步是否有材质成分

  @observable public initialValues = {
    goodsName: '',
    shortTitle: '',
    sellingPoint: '',
    cids: undefined,
    xinpin: 'true',
  };

  @observable public classificationList: Array<{ label: string; value: string | number; }>;

  @observable public baseinfoFormRef = React.createRef<FormInstance>();

  @action public changeInput = (e, type, maxLength) => {
    let valueTmp = e.target.value;
    if (getStrLen(valueTmp) > maxLength) {
      valueTmp = getStr(valueTmp, maxLength);
    }
    const { setFieldsValue } = this.baseinfoFormRef.current;
    setFieldsValue({ [type]: valueTmp });
    Object.assign(this.initialValues, { [type]: valueTmp });
  };

  @action public formItemOnChange = (e, info, value, optionObj) => {
    const { type, propsKey, index } = info;
    const { goodsPropsValues, cascadeProps, goodsProps } = this.parent.tmallStepOneStore;
    const cascadePropsKeys = Object.keys(cascadeProps);
    const _categoryProps = toJS(goodsProps);
    let _index = index;
    const propsvalues = Object.assign(goodsPropsValues, this.parent.goodsPropsValuesTmp);
    console.log(info, ' v-- ', value, 'optionObj--', optionObj);

    // if (type === 'input') {
    //   Object.assign(propsvalues, {
    //     [propsKey]: {
    //       preV: propsvalues[propsKey]?.currentV || '',
    //       currentV: e.target.value,
    //     },
    //   });
    // }
    if (type === 'select') {
      Object.assign(propsvalues, {
        [propsKey]: {
          preV: propsvalues[propsKey]?.currentV || '',
          currentV: typeof e === 'string' ? e : (e && e.join(',')),
        },
      });
      const isCascade = cascadePropsKeys.find((item) => item === `${propsKey}_${e}`);
      const isCascadePre = cascadePropsKeys.find((item) => item === `${propsvalues[propsKey]?.preV ? `${propsKey}_${propsvalues[propsKey].preV}` : ''}`);
      _index++;
      if (isCascadePre) {
        _categoryProps.splice(_index, 1);
      }
      if (isCascade) {
        const cascadePropsItem = toJS(cascadeProps[`${propsKey}_${e}`]);
        cascadePropsItem.required = true;
        _categoryProps.splice(_index, 0, cascadePropsItem);
      }
    }
    this.parent.tmallStepOneStore.goodsProps = _categoryProps;
  };

  @action public getBaseInfo = () => {
    const { getFieldsValue } = this.baseinfoFormRef.current;
    const values = getFieldsValue();
    const obj = {};
    Object.keys(values).map((item) => {
      if (typeof values[item] === 'object') {
        obj[item] = values[item] && values[item].join(',');
      } else {
        obj[item] = values[item];
      }
    });
    return { ...obj };
  };

  @action public writeBackBaseinfo = (info) => {
    const { setFieldsValue } = this.baseinfoFormRef.current;
    setFieldsValue(info);
    Object.assign(this.initialValues, info);
  };
}
