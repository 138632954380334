import { Form, Input, Row, Col, Radio, Select, Checkbox, Space, Button, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { limitDecimals } from '../../../../../utils';
import { ContentWrap } from '../../../../shelvesBase';
import styles from '../index.less';

const { Item } = Form;

const freight = [
  {
    label: '平邮',
    value: 'postFee',
  },
  {
    label: '快递',
    value: 'expressFee',
  },
  {
    label: 'EMS',
    value: 'emsFee',
  },
];

export const LogisticInfo = observer((props: { tianmaoStore; }) => {
  const { provinceList, cityList, province, provinceId, cityId, extractWay,
    city, handleChangeSelect, changeTiqu, postFeeMode, changeFreit, freightTemplateList, changefreightTpl, flushDeliveryTemplates, deliveryTemplateLoading, changeFreight, deliveryTemplateId, changeFeeTpl, feeTpl } = props.tianmaoStore;
  return (
    <ContentWrap
      id="logisticsInfo"
      text="物流信息"
    >
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          <span className={styles.red}>
            *
          </span>
          所在地：
        </Col>
        <Col
          className={styles.colContent}
          id="placeWrap"
          span={21}
        >
          <Radio checked>
            大陆及港澳台
          </Radio>
          <div className={styles.placeWrap}>
            <Select
              onChange={(value) => {
                handleChangeSelect(value, 'provinceId');
              }}
              options={provinceList}
              style={{ width: 130 }}
              value={province}
            />
            <Select
              onChange={(value) => {
                handleChangeSelect(value, 'cityId');
              }}
              options={cityList}
              style={{
                width: 130,
                marginLeft: 20,
              }}
              value={city}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          id="extractWay"
          span={3}
        >
          <span className={styles.red}>
            *
          </span>
          提取方式：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <div
            className={styles.placeWrap}
            style={{ marginTop: 0 }}
          >
            <Checkbox.Group
              onChange={changeTiqu}
              style={{ width: '100%' }}
              value={extractWay}
            >
              <Row>
                <Col span={24}>
                  <Checkbox
                    disabled
                    value="electronicTradeVoucher"
                  >
                    电子交易凭证
                  </Checkbox>
                </Col>
                <Col
                  span={24}
                  style={{ marginTop: 16 }}
                >
                  <Checkbox value="2">
                    邮寄
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
            {
              extractWay.includes('2') && (
                <div className={styles.showFreit}>
                  <Radio.Group
                    onChange={changeFreit}
                    value={postFeeMode}
                  >
                    <Space direction="vertical">
                      <Radio value="2">
                        卖家承担运费
                      </Radio>
                      <Radio value="1">
                        买家承担运费
                      </Radio>
                    </Space>
                  </Radio.Group>
                  <div
                    className={styles.freightSum}
                    onClick={() => {
                      window.open('https://wuliu.taobao.com/user/logis_tools.htm');
                    }}
                  >
                    运费计算器
                  </div>
                </div>
              )
            }
            {
              postFeeMode === '1' && (
                <div className={styles.feeTplWrap}>
                  <Radio.Group
                    onChange={changeFeeTpl}
                    value={feeTpl}
                  >
                    <Space direction="vertical">
                      <Radio value="postage">
                        使用运费模板
                      </Radio>
                          
                      <Radio value="freight_details">
                        自定义运费
                      </Radio>
                          
                    </Space>
                  </Radio.Group>
                  {feeTpl === 'postage' && (
                    <div className={styles.feeTpl}>
                      <span>
                        运费模板
                      </span>
                      <span className={styles.red}>
                        *
                      </span>
                      <Select
                        className={styles.freightTemplateList}
                        loading={deliveryTemplateLoading}
                        onChange={changefreightTpl}
                        options={freightTemplateList}
                        placeholder="请选择运费模板"
                        style={{
                          width: 280,
                          margin: '0 24px 0 24px',
                        }}
                        value={deliveryTemplateId}
                      />
                      <Button onClick={flushDeliveryTemplates}>
                        刷新模板数据
                      </Button>
                    </div>
                  )}
                  {
                    feeTpl === 'freight_details' && (
                      <div className={styles.freightWrap}>
                        
                        {
                          freight.map((item) => {
                            const { label, value } = item;
                            return (
                              <div
                                className={styles.freight}
                                key={value}
                              >
                                <div>
                                  {label}
                                </div>
                                <InputNumber
                                  onChange={(e) => {
                                    changeFreight(e, value);
                                  }}
                                  parser={limitDecimals}
                                  style={{ margin: '0 8px' }}
                                  value={props.tianmaoStore[value]}
                                />
                          
                                <div>
                                  元
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </Col>
      </Row>
    </ContentWrap>
  );
});

