import { message, Modal } from 'antd';

import BraftEditor from 'braft-editor';
import { ContentUtils } from 'braft-utils';
import { request } from 'egenie-utils';
import { debounce } from 'lodash';
import { action, observable } from 'mobx';
import { api, scrollToAnchor } from '../../../../utils';
import { dealUnlegalImg, getImageInfo } from '../../../shelvesBase';

export class EditorStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public editorState = BraftEditor.createEditorState(null);

  // 清空内容
  @action public clearContent = () => {
    Modal.confirm({
      title: '提示',
      content: '确认清空吗?',
      onOk: () => {
        this.editorState = ContentUtils.clear(this.editorState);
      },
    });
  };

  @action public handleEditorChange = debounce((editorState) => {
    console.log('TCL: EditorStore -> handleEditorChange -> editorState', editorState);
    this.editorState = editorState;
  }, 500);

  @action public uploadImgCallback = (fileList) => {
    const { editorState } = this;
    const _editorState = ContentUtils.insertMedias(
      editorState,
      fileList.map((v) => ({
        type: 'IMAGE',
        url: v.url,
      }))
    );
    this.editorState = _editorState;
  };

  public writeBackEditor = (id) => {
    request<any>({
      url: api.getEditorInfo,
      method: 'post',
      data: { gmsGoodsId: Number(id) },
    }).then(async(v) => {
      this.editorState = ContentUtils.clear(this.editorState);

      // 在此处处理详情图片格式
      const dealedData = await this.dealDetailPicFormat(v.data);
      if (v.data) {
        this.editorState = ContentUtils.insertHTML(this.editorState, this.getHtmlContent(dealedData));
      }
    })
      .catch(() => {
        this.editorState = ContentUtils.clear(this.editorState);
      });
  };

  @action public getHtmlContent = (content: string): string => {
    if (content) {
      return `${content}<p></p>`;
    }
    return '';
  };

  @action public fullHtml = (content) => {
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
       <title>详情预览</title>
       <meta charset="utf-8" />
       <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
       <meta http-equiv="Cache-Control" content="no-store" />
       <meta http-equiv="expires" content="0">
       <meta http-equiv="expires" content="Tue, 01 Jan 1980 1:00:00 GMT" />
       <meta http-equiv="Pragma" content="no-cache">
       <meta name="viewport" content="width=device-width, initial-scale=1" />
    </head>
    <body>
    ${content}
    </body>
    </html>`;
  };

  @action public validateContent = (): any => {
    const isEmpty = this.editorState.isEmpty();
    if (isEmpty) {
      return false;
    }
    if (!isEmpty) {
      const { blocks } = this.editorState.toRAW(true);
      for (const item of blocks) {
        const { text, entityRanges } = item;
        if (text.trim() || entityRanges.length) {
          return true;
        }
      }
      return false;
    }
  };

  @action public relaseContent = (type) => {
    if (type === 'submit' && !this.validateContent()) {
      // message.error('请填写图文详情！');
      scrollToAnchor('imgsAndContent');
      return false;
    }

    const htmlContent = this.editorState.toHTML().replace(/(<\/div>)(<p><\/p>)+/img, '$1'); // 去掉空的p标签

    const formData = new FormData();
    formData.append('file', new File([htmlContent], 'index.html'));
    formData.append('moduleType', 'app');
    formData.append('title', 'htmlContent');

    return request<any>({
      url: api.uploadFile,
      method: 'post',
      data: formData,
    }).then((v) => {
      return v;
    });
  };

  // 处理详情图片格式
  private dealDetailPicFormat = async(data) => {
    const detailRoot = document.createElement('div');
    detailRoot.innerHTML = data;

    // 获取详情的所有图片
    const imgs = detailRoot.querySelectorAll('img');
    for (let i = 0; i < imgs.length; i++) {
      const { src } = imgs[i];
  
      const { Format } = await getImageInfo(src);
      
      const newUrl = dealUnlegalImg(src, Format.value);
      imgs[i].src = newUrl;
    }
    return detailRoot.innerHTML;
  };
}
