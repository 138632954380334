import { Row, Col } from 'antd';
import BraftEditor from 'braft-editor';
import Table from 'braft-extensions/dist/table';
import { observer } from 'mobx-react';
import React from 'react';

// import '../../../../../braftIcons.css';
import 'braft-editor/dist/index.css';
import 'braft-extensions/dist/table.css';
import UploadMainImgs from '../mainImgs/uploadMainImgs';
import styles from './index.less';

const options = {
  defaultColumns: 3, // 默认列数
  defaultRows: 3, // 默认行数
  withDropdown: true, // 插入表格前是否弹出下拉菜单
  exportAttrString: '', // 指定输出HTML时附加到table标签上的属性字符串
};

BraftEditor.use(Table(options));

const Editor = observer((props) => {
  const { store: { editorState, handleEditorChange, clearContent, uploadMainImgsStore }, singleUploadImgStore } = props;
  const { selectImg } = singleUploadImgStore;
  const excludeControls: any = ['media']; // 排除的组件
  const extendControls: any = [
    {
      key: 'antd-uploader',
      type: 'component',
      component: (

        // <UploadMainImgs
        //   btnName="插入图片"
        //   customClassname="control-item button upload-button"
        //   store={uploadMainImgsStore}
        // />
        <button
          className="control-item button upload-button"
          data-title="插入图片"
          onClick={() => {
            selectImg('editor');
          }}
          type="button"
        >
          插入图片
        </button>
      ),
    },
    {
      key: 'antd-clear',
      type: 'component',
      component: (
        <button
          className="control-item button clear-button"
          data-title="清空"
          onClick={clearContent}
          type="button"
        >
          清空
        </button>
      ),
    },
  ];
  return (
    <BraftEditor
      className={styles.editorWrap}
      excludeControls={excludeControls}
      extendControls={extendControls}
      onChange={handleEditorChange}
      value={editorState}
    />
  );
});

export { Editor };
