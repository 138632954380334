
import { message } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { observable, action, computed } from 'mobx';
import React from 'react';
import { api, dataURLtoFile } from '../../../../../utils';

interface IChecklist {
  label: string;
  type: string;
  value: string;
  isShow: boolean;
}

class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public visible = false;

  @observable public btnLoading = false;

  @observable public tagMap = {
    brandName: '', // 品牌
    goodsNo: '', // 货号
    goodsCategoryName: '', // 类目
    composition: '', // 成分
    level: '合格品', // 等级
    standard: '', // 执行标准
    securityLevel: '', // 安全级别
  };

  @observable public tags = [
    {
      type: 'brandName',
      label: '品牌',
    },
    {
      type: 'goodsNo',
      label: '货号',
    },
    {
      type: 'goodsCategoryName',
      label: '类目',
    },
    {
      type: 'composition',
      label: '成分',
    },
    {
      type: 'level',
      label: '等级',
    },
    {
      type: 'standard',
      label: '执行标准',
    },
    {
      type: 'securityLevel',
      label: '安全级别',
    },
  ];

  @observable public checkedValues = [];

  @observable public checkList = [
    {
      label: '品牌',
      type: 'brandName',
      value: '',
      isShow: false,
    },
    {
      label: '货号',
      type: 'goodsNo',
      value: '',
      isShow: false,
    },
    {
      label: '类目',
      type: 'goodsCategoryName',
      value: '',
      isShow: false,
    },
    {
      label: '成分',
      type: 'composition',
      value: '',
      isShow: false,
    },
    {
      label: '等级',
      type: 'level',
      value: '合格品',
      isShow: false,
    },
    {
      label: '执行标准',
      type: 'standard',
      value: '',
      isShow: false,
    },
    {
      label: '安全级别',
      type: 'securityLevel',
      value: '',
      isShow: false,
    },
  ];

  @action public changeCheckBox = (checkedValues) => {
    this.checkedValues = checkedValues;
    const checkList = this.checkList;
    for (let i = 0; i < checkList.length; i++) {
      if (checkedValues.includes(checkList[i].type)) {
        checkList[i].isShow = true;
      } else {
        checkList[i].isShow = false;
      }
    }
    this.checkList = checkList;
  };

  @action public changeInput = (e, type) => {
    this.tagMap[type] = e.target.value;
    const checkList = this.checkList.map((item) => {
      if (item.type === type) {
        item.value = e.target.value;
      }
      return item;
    });
    this.checkList = checkList;
  };

  @action public showModal = () => {
    this.parent.generateDurabilityInfoStore.closeModal();
    this.visible = true;
  };

  @action public closeModal = () => {
    this.visible = false;
  };

  @observable public getObjectURL = (file): any => {
    let url = null;
    if (window.URL != undefined) {
      url = window.URL.createObjectURL(file);
    } else if (window.webkitURL != undefined) {
      url = window.webkitURL.createObjectURL(file);
    }
    return url;
  };

  // 获取未填写label
  @action public getNullLabel = (isNullArr: IChecklist[]): string[] => {
    return isNullArr.reduce((prev, cur) => {
      return prev.concat(cur.label);
    }, []);
  };

  // 吊牌信息不能为空，并且精准提示
  @action public checkTagsNull = (type: string, checkList: IChecklist[]): boolean => {
    if (type === 'tags') {
      const isNullArr = checkList.filter((item) => !item.value);
      if (isNullArr.length > 0) {
        const allNullLabel = this.getNullLabel(isNullArr).join(',');
        message.error(`未填写${allNullLabel}`);
        return false;
      }
      return true;
    }
    return true;
  };

  // 生成图片
  @action public generatePic = (type: string, checkList: IChecklist[], messageInfo: string, canvasId: string, seq: number): void => {
    if (checkList.length === 0) {
      message.error(messageInfo);
    } else {
      if (this.checkTagsNull(type, checkList)) {
        this.btnLoading = true;
        this.parent.generateDurabilityInfoStore.btnLoading = true;
        const canvas2 = document.createElement('canvas');
        const _canvas = document.getElementById(canvasId);
        const w = parseInt(window.getComputedStyle(_canvas).width, 10);
        const h = parseInt(window.getComputedStyle(_canvas).height, 10);
        canvas2.width = w;
        canvas2.height = h;
        const context = canvas2.getContext('2d');
        context.scale(1, 1);

        // html转化成canvas
        html2canvas(_canvas, {
          canvas: canvas2,
          scale: 1,
          useCORS: true,
        }).then((canvas) => {
        // this.visible = false;
          const imgUrl = canvas.toDataURL('image/png');
          const imgFile = this.dataURLtoFile(imgUrl, 'file');
          this.uploadImg(imgFile, seq);
        });
      }
    }
  };

  // base64转file
  @action public dataURLtoFile = (dataurl: string, filename: string): File => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const suffix = mime.split('/')[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], `${filename}.${suffix}`, { type: mime });
  };

  @action public uploadImg = (file, seq) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('moduleType', 'pc');
    formData.append('title', 'url');
    request<BaseData<any>>({
      url: api.uploadFile,
      method: 'post',
      data: formData,
    }).then((res) => {
      this.btnLoading = false;
      this.parent.generateDurabilityInfoStore.btnLoading = false;
      this.visible = false;
      this.parent.generateDurabilityInfoStore.visible = false;
      this.parent.tianmaoStore.qualificationPics[seq].fileList = [{ url: res.data }];
    })
      .catch(() => {
        this.btnLoading = false;
        this.parent.generateDurabilityInfoStore.btnLoading = false;
        this.visible = false;
        this.parent.generateDurabilityInfoStore.visible = false;
      });
  };

  @action public writeBackTagsInfo = (brandName, goodsNo, goodsCategoryName) => {
    this.tagMap.brandName = brandName || '';
    this.tagMap.goodsNo = goodsNo || '';
    this.tagMap.goodsCategoryName = goodsCategoryName || '';
    const checkList = this.checkList;
    checkList[0].value = brandName;
    checkList[1].value = goodsNo;
    checkList[2].value = goodsCategoryName;
    this.checkList = checkList;
  };
}

export default Store;
function ReactInstance(ReactInstance: any) {
  throw new Error('Function not implemented.');
}

