import { Button } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { SingleUploadImg } from '../../../../shelvesBase';
import GenerateDurabilityInfo, { ShowAllIconsModal } from '../generateDurabilityInfo';
import type GenerateDurabilityInfoStore from '../generateDurabilityInfo/store';
import GenerateTagsInfo from '../generateTagsInfo';
import type GenerateTagsInfoStore from '../generateTagsInfo/store';
import styles from '../index.less';
import type tianmaoStore from '../store';

const QualificationPic = observer((props: { store; tianmaoStore: tianmaoStore; generateDurabilityInfoStore: GenerateDurabilityInfoStore; generateTagsInfoStore: GenerateTagsInfoStore; singleUploadImgStore; }) => {
  const { selectImg, deleteImg } = props.singleUploadImgStore;
  const { qualificationPics } = props.tianmaoStore;
  return (
    <div
      className={styles.qualificationPicWrap}
      id="qualificationPics"
    >
      {
        qualificationPics.map((item) => (
          <div
            className={`${styles.uploadItem} ${item.fileList.length >= 1 ? styles.fileListItem : ''} ${item.status === 'unDone' ? styles.unDone : ''}`}
            key={item.type}
          >
            <SingleUploadImg
              deleteImg={() => {
                deleteImg(`qualify${item.type}`);
              }}
              fileList={item.fileList}
              selectImg={selectImg}
              type={`qualify${item.type}`}
            />

            {
              (item.type === 'attach51' || item.type === 'attach52') && (
                <span className={styles.red}>
                  *
                </span>
              )
            }
            <span
              className={styles.itemName}
              style={{ marginLeft: 4 }}
            >
              {item.name}
            </span>
            {
              item.type === 'attach51' && (
                <Button
                  className={styles.editProductBtn}
                  id="generateTags"
                  onClick={props.generateTagsInfoStore.showModal}
                  style={{ position: 'relative' }}
                >
                  生成吊牌图
                </Button>
              )
            }
            {
              item.type === 'attach52' && (
                <Button
                  className={styles.editProductBtn}
                  id="generateDurabilityInfo"
                  onClick={props.generateDurabilityInfoStore.showModal}
                >
                  生成耐久性标签
                </Button>
              )
            }
          </div>
        ))
      }
      <GenerateTagsInfo generateTagsInfoStore={props.generateTagsInfoStore}/>
      <GenerateDurabilityInfo generateDurabilityInfoStore={props.generateDurabilityInfoStore}/>
      <ShowAllIconsModal generateDurabilityInfoStore={props.generateDurabilityInfoStore}/>
    </div>
  );
});

export default QualificationPic;

