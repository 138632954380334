import { RedoOutlined } from '@ant-design/icons';
import { Form, Select, Button } from 'antd';
import React from 'react';
import { ContentWrap } from '../../../../shelvesBase';
import type { IEachProps } from '../common';
import styles from '../index.less';

const { Item } = Form;

export const LogisticsInfo = (props: IEachProps) => {
  const { store } = props;
  const { deliveryTemplatesOptions, deliveryTemplateLoading, refreshDeliveryTemplate } = store;
  return (
    <ContentWrap
      id="logisticsinfo"
      text="物流信息"
    >
      <Item
        label="提取方式"
      >
        使用物流配送
      </Item>
      <Item
        label="运费模版"
        required
        wrapperCol={{ span: 6 }}
      >
        
        <div className={styles.specFormItem}>
          <Item
            className={styles.innerItem}
            name="deliveryTemplateId"
            noStyle
            rules={[
              {
                required: true,
                message: '请选择运费模版',
              },
            ]}
          >
            <Select
              loading={deliveryTemplateLoading}
              options={deliveryTemplatesOptions}
              showSearch
            />
          </Item>
          <Button
            icon={<RedoOutlined/>}
            onClick={refreshDeliveryTemplate}
            style={{ marginLeft: '8px' }}
          >
            刷新数据模版
          </Button>
        </div>
      </Item>
    </ContentWrap>
  );
};

