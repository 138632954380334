import { Form, Input, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, SingleUploadImg } from '../../../../shelvesBase';
import { Editor, MainImgs } from '../../../components';
import styles from '../index.less';

import QualificationPic from './qualificationPic';

export const ProductDescription = observer((props: { store; tianmaoStore;singleUploadImgStore; mainImgsStore; generateDurabilityInfoStore; generateTagsInfoStore; editorStore; }) => {
  const { singleUploadImgStore, tianmaoStore } = props;
  const { selectImg, deleteImg } = singleUploadImgStore;
  return (
    <ContentWrap
      id="goodsDesc"
      text="商品描述"
    >
      <Row>
        <Col
          className={styles.colTitle}
          id="mainImgs"
          span={3}
        >
          <span className={styles.red}>
            *
          </span>
          商品主图：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <MainImgs
            singleUploadImgStore={singleUploadImgStore}
            store={props.mainImgsStore}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          资质图片：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
          style={{ marginBottom: 89 }}
        >
          <QualificationPic
            generateDurabilityInfoStore={props.generateDurabilityInfoStore}
            generateTagsInfoStore={props.generateTagsInfoStore}
            singleUploadImgStore={singleUploadImgStore}
            store={props.store}
            tianmaoStore={props.tianmaoStore}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          id="verticalImage"
          span={3}
        >
          <span className={styles.red}>
            *
          </span>
          商品长图：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <div className={styles.goodsImgWrap}>
            <SingleUploadImg
              deleteImg={() => {
                deleteImg('fullPics');
              }}
              fileList={tianmaoStore.verticalImage}
              imgSize={{
                width: 800,
                height: 1200,
              }}
              selectImg={selectImg}
              type="fullPics"
            />
            <div className={styles.imgInfo}>
              为了能更好的展示服装的上身效果,提高前台点击率,建议上传的长图为
              <span className={styles.blue}>
                模特图
              </span>
              ,图片要求尽量是场景图,有故事和画面感。上传符合规范的图片,您的图片会在手机淘宝猜你喜欢,手淘搜素等入口均有优先透出机会图片不得出现品牌LGO,不得出现任何形式的边框,不得出现水印,不得出现促销等文字说明,图片尺寸为
              <span className={styles.blue}>
                800*1200
              </span>
              ，大小不超过500kb
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          <span className={styles.red}>
            *
          </span>
          透明素材图：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <div className={styles.goodsImgWrap}>
            <SingleUploadImg
              deleteImg={() => {
                deleteImg('transPics');
              }}
              fileList={tianmaoStore.whiteBgImage}
              imgSize={{
                width: 800,
                height: 800,
              }}
              selectImg={selectImg}
              type="transPics"
            />
            {/* <UploadImg
              fileList={props.tianmaoStore.whiteBgImage}
              required
              store={props.store}
              tianmaoStore={props.tianmaoStore}
              type="whiteBgImage"

              uploadImgStore={props.uploadImgStore}
            /> */}
            <div className={styles.imgInfo}>
              <p>
                上传PNG的透明底图片后,参加天猫言方活动将无需再提交对应的图片素材,并目享有言方活动会场等场景优先岀的机会,同时在天猫App搜素,导购入口,推荐等场景内享有优先露出机会,如该商品在日常场景被优先露出,则会将该图片替换为优先露出场景的商品主图。
              </p>
              请将素材抠图、边缘处理千净、居中放入
              <span className={styles.blue}>
                透明、正方形
              </span>
              画布中,素材在画板内尽量撑满,但不要超出画板边界仅支持pNG格式,图片尺寸必须为
              <span className={styles.blue}>
                800*800
              </span>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          span={3}
        >
          手机图文详情：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <div className={styles.mobileInfo}>
            由PC端描述自动生成
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          className={styles.colTitle}
          id="imgsAndContent"
          span={3}
        >
          <span className={styles.red}>
            *
          </span>
          图文详情：
        </Col>
        <Col
          className={styles.colContent}
          span={21}
        >
          <Editor
            singleUploadImgStore={singleUploadImgStore}
            store={props.editorStore}
          />
        </Col>
      </Row>
    </ContentWrap>
  );
});

