import { InputNumber, Input } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ShowCount } from '../showCount';
import styles from './index.less';

const { TextArea } = Input;

const TableInfo = observer((props) => {
  const { tableHeader, tableList, changeValue, changeInput, errorList } = props.tableStore;
  return (
    <div
      className={styles.tableInfoWrap}
      id="tableInfo"
    >
      <div className={styles.tableHeaderWrap}>
        {
          tableHeader.map((item, index) => {
            return (
              <div
                className={styles.tableHeader}
                key={item.title}
                style={{ width: (index === 0 || index === 1 || index === 4) ? 172 : 97 }}
              >
                {
                  item.required && (
                    <span className={styles.red}>
                      *
                    </span>
                  )
                }
                {item.title}
              </div>
            );
          })
        }
      </div>
      <div className={styles.tableContentWrap}>
        {
          tableList.map((item, index) => {
            const { clothesSize, modelName, height, weight, tryOnExperience, bust, waists, hip } = item;
            const obj = errorList && errorList.length > 0 && errorList.find((el) => el.id === item.id);
            return (
              <div
                className={styles.tableContent}
                key={item.id}
                style={{ borderBottom: index === 2 ? 'none' : '1px solid #f0f0f0' }}
              >
                <div className={styles.tableTd}>
                  <Input
                    className={styles.wenzi}
                    id={`${item.id}_clothesSize`}
                    maxLength={10}
                    onChange={(e) => {
                      changeInput({
                        type: 'clothesSize',
                        id: item.id,
                      }, e);
                    }}
                    style={{ borderColor: obj && obj.errorList.includes('clothesSize') ? '#F2270A' : '' }}
                    value={clothesSize}
                  />
                  <ShowCount
                    maxLength={10}
                    style={{ top: 16 }}

                    value={clothesSize}
                  />
                </div>
                <div className={styles.tableTd}>
                  <Input
                    className={styles.wenzi}
                    id={`${item.id}_modelName`}
                    maxLength={10}
                    onChange={(e) => {
                      changeInput({
                        type: 'modelName',
                        id: item.id,
                      }, e);
                    }}
                    style={{ borderColor: obj && obj.errorList.includes('modelName') ? '#F2270A' : '' }}
                    value={modelName}
                  />
                  <ShowCount
                    maxLength={10}
                    style={{ top: 16 }}
                    value={modelName}
                  />
                </div>
                <div className={styles.tableTd}>
                  <InputNumber
                    className={styles.shuzi}
                    id={`${item.id}_height`}
                    onChange={(value) => {
                      changeValue({
                        type: 'height',
                        id: item.id,
                      }, value);
                    }}
                    style={{ borderColor: obj && obj.errorList.includes('height') ? '#F2270A' : '' }}
                    value={height}
                  />
                </div>
                <div className={styles.tableTd}>
                  <InputNumber
                    className={styles.shuzi}
                    id={`${item.id}_weight`}
                    onChange={(value) => {
                      changeValue({
                        type: 'weight',
                        id: item.id,
                      }, value);
                    }}
                    value={weight}
                  />
                </div>
                <div className={styles.tableTd}>
                  <TextArea
                    autoSize
                    className={styles.wenzi}
                    id={`${item.id}_tryOnExperience`}
                    onChange={(e) => {
                      changeInput({
                        type: 'tryOnExperience',
                        id: item.id,
                      }, e);
                    }}
                    value={tryOnExperience}
                  />
                </div>
                <div className={styles.tableTd}>
                  <InputNumber
                    className={styles.shuzi}
                    id={`${item.id}_bust`}
                    onChange={(value) => {
                      changeValue({
                        type: 'bust',
                        id: item.id,
                      }, value);
                    }}
                    value={bust}
                  />
                </div>
                <div className={styles.tableTd}>
                  <InputNumber
                    className={styles.shuzi}
                    id={`${item.id}_waists`}
                    onChange={(value) => {
                      changeValue({
                        type: 'waists',
                        id: item.id,
                      }, value);
                    }}
                    value={waists}
                  />
                </div>
                <div className={styles.tableTd}>
                  <InputNumber
                    className={styles.shuzi}
                    id={`${item.id}_hip`}
                    onChange={(value) => {
                      changeValue({
                        type: 'hip',
                        id: item.id,
                      }, value);
                    }}
                    value={hip}
                  />
                </div>
              </div>
            );
          })
        }
      </div>
    </div>
  );
});

export default TableInfo;

