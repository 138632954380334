import { Form, Radio, Checkbox } from 'antd';
import React from 'react';
import { ContentWrap } from '../../../../shelvesBase';

const { Item } = Form;
 
export const PayInfo = () => {
  return (
    <ContentWrap
      id="payinfo"
      text="支付信息"
    >
    
      <Item
        label="付款方式"
        name="payType"
        rules={[
          {
            required: true,
            message: '请选择付款',
          },
        ]}
      >
        <Radio.Group>
          <Radio value="b">
            一口价(普通交易模式)
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        label="库存计数"
        name="stockCountMode"
        rules={[
          {
            required: true,
            message: '请选择库存计数方式',
          },
        ]}

      >
        <Radio.Group>
          <Radio value="1">
            买家拍下减库存
          </Radio>
          <Radio value="0">
            买家付款减库存
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        label="会员打折"
        name="memberDiscount"
        rules={[
          {
            required: true,
            message: '请选择是否会员打折',
          },
        ]}
      >
        <Radio.Group>
          <Radio value="1">
            不参与会员打折
          </Radio>
          <Radio value="2">
            参与会员打折
          </Radio>
        </Radio.Group>
      </Item>
      <Item label="售后服务">
        <Item
          name="invoice"
          noStyle
          valuePropName="checked"
        >
          <Checkbox>
            提供发票
          </Checkbox>
        </Item>
      </Item>
      <Item
        colon={false}
        label="  "
      >
        <Item
          name="afterSaleService"
          noStyle
          valuePropName="checked"
        >
          <Checkbox value="1">
            退换货承诺
          </Checkbox>
        </Item>
      </Item>
    </ContentWrap>
  );
};

