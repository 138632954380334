import { message } from 'antd';
import { EgGridModel } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, toJS, autorun } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { scrollToAnchor } from '../../../../utils';
import { SkuBaseStore } from '../../../shelvesBase';
import { saleSpecColumns } from './columns';

export class SizeColorStore extends SkuBaseStore {
  constructor(parent) {
    super();
    this.parent = parent;
    autorun(() => {
      this.productSaleSpecGridModel.columns[5].ejlHidden = this.parent?.taobaoFormRef;
    });
  }

  @observable public parent;

  @observable public allPrice: number;// 批量价格

  @observable public allNum: number;// 批量数量

  @observable public forbidCustomSize = false;// 禁止自定义尺码

  // 商品规格表
  @observable public productSaleSpecGridModel = new EgGridModel({
    columns: saleSpecColumns(this),
    rows: [],
    showPagination: false,
    showSelectedTotal: false,
    showCheckBox: false,
    showRefresh: false,
    showPager: false,
    primaryKeyField: 'goodsSpec',
  });

  // 映射sku列表为商品规格表格的行
  @action public mapSpecRows = (arr) => {
    return arr.map((item) => {
      const { goodsSkuId, goodsSpec, goodsSkuNo, salePrice, stockNum, skuMarketTime, costPrice } = item;
      const goodsSpecTemp = goodsSpec.split(';');

      const colorAndSize = {
        color: goodsSpecTemp[0].split(':')[1],
        size: goodsSpecTemp[1].split(':')[1],
      };

      return {
        goodsSpec,
        costPrice,
        goodsSkuId,
        goodsSkuNo,
        salePrice,
        stockNum,
        skuMarketTime: skuMarketTime ? moment(skuMarketTime) : '',
        ...colorAndSize,
      };
    });
  };

  // 计算数量及一口价
  @action public calcNumAndPrice = () => {
    if (!this.productSaleSpecGridModel.rows.length) {
      // 淘宝
      if (this.parent.taobaoFormRef) {
        this.parent.taobaoFormRef.current?.setFieldsValue({
          salePrice: 0,
          stockNum: 0,
        });
      } else {
        this.parent.saleInfoRef.current?.setFieldsValue({
          salePrice: 0,
          stockNum: 0,
        });
      }
    }

    // 一口价为sku中最低售价(只计算有数量的sku)
    const salePriceList = this.productSaleSpecGridModel.rows.filter((item) => item.stockNum).map((item) => item.salePrice);
    const salePrice = salePriceList.length ? Math.min(...salePriceList) : 0;
  
    // 数量
    let stockNum = 0;
    this.productSaleSpecGridModel.rows.forEach((item) => {
      stockNum += item.stockNum;
    });

    if (this.parent.taobaoFormRef) {
      this.parent.taobaoFormRef.current?.setFieldsValue({
        salePrice,
        stockNum,
      });
    } else {
      this.parent.saleInfoRef.current?.setFieldsValue({
        salePrice,
        stockNum,
      });
    }
  };

  // 生成skulist
  @action public generateSkuList = () => {
    const checkedColorList = this.colorList.filter((item) => item.checked);
    const checkedSizeList = this.sizeList.filter((item) => item.checked);

    // 二重循环生成sku list
    const newList = [];
    checkedColorList.forEach((colorItem) => {
      checkedSizeList.forEach((sizeItem) => {
        const colorName = colorItem.name;
        const { name } = sizeItem;
        const goodsSpec = `${this.colorPropName}:${colorName};${this.sizePropName}:${name}`;// spec
        const eachSku = {
          goodsSpec,
          goodsSkuNo: '',
          stockNum: null,
          salePrice: null,
        };
        newList.push(eachSku);
      });
    });

    // 只是编辑颜色或尺码
    if (this.goodsSkuList.length === newList.length) {
      return;
    }
  
    let diffList;

    // 判断减少还是增加sku
    if (this.goodsSkuList.length > newList.length) {
      if (!newList.length) {
        diffList = this.goodsSkuList;
      } else {
        diffList = _.differenceBy(this.goodsSkuList, newList, 'goodsSpec');
      }

      // 减少sku的话如果减少的sku中含有skuId是初使状态下就有的sku 要保存起来 防止又重新勾选
      const saveList = diffList.filter((item) => item.goodsSkuId);
      this.tempSaveSkuList.push(...saveList);

      this.goodsSkuList = _.intersectionBy(this.goodsSkuList, newList, 'goodsSpec');
    } else {
      if (!this.goodsSkuList.length) {
        diffList = newList;
      } else {
        diffList = _.differenceBy(newList, this.goodsSkuList, 'goodsSpec');
      }
      
      // 新增sku
      // 检查新增sku是否是初始状态下就有的
      const initList = _.intersectionBy(this.tempSaveSkuList, diffList, 'goodsSpec');

      this.goodsSkuList.push(...initList);
      const restList = _.differenceBy(diffList, initList, 'goodsSpec');

      this.goodsSkuList.push(...restList);
    }

    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
  };

  // /售价数量修改
  // 修改单个sku售价
  @action public handleSingleSalePriceChange = (spec, price) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].salePrice = price;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
  };

  // 修改所有sku售价(Change)
  @action public handleAllSalePriceChange = (price) => {
    this.allPrice = price;
  };

  // 修改sku Blur
  @action public handleSkuChange = (spec, val) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].goodsSkuNo = val;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // blur
  @action public handleAllSalePriceBlur = () => {
    if (!this.allPrice) {
      return;
    }
    this.goodsSkuList.forEach((item) => {
      item.salePrice = this.allPrice;
    });
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
    this.allPrice = null;
  };

  // 处理批量填写上市时间
  public handleAllSkuMarketTimeChange = (time: Moment) => {
    this.goodsSkuList.forEach((item) => {
      item.skuMarketTime = `${time.format('YYYY-MM-DD')}`;
    });
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 处理单个填写上市时间
  public handleSingleSkuMarketTimeChange = (spec, time: Moment) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    const newTime = time ? `${time.format('YYYY-MM-DD')}` : '';
    this.goodsSkuList[index].skuMarketTime = newTime;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
  };

  // 添加利润
  @action public handleSalePriceAddProfit = (profit: number) => {
    this.goodsSkuList.forEach((item) => {
      if (item.costPrice) {
        item.salePrice = item.costPrice + profit;
      } else {
        item.salePrice = null;
      }
    });
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
    scrollToAnchor('skuTable');
  };

  @action public handleAllNumChange = (num) => {
    this.allNum = num;
  };

  @action public handleAllNumBlur = () => {
    if (!this.allNum) {
      return;
    }
    this.goodsSkuList.forEach((item) => {
      item.stockNum = this.allNum;
    });

    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
    this.allNum = null;
  };

  // 修改单个sku数量
  @action public handleSingleNumChange = (spec, num) => {
    const index = this.goodsSkuList.findIndex((item) => item.goodsSpec === spec);
    this.goodsSkuList[index].stockNum = num;
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
  };

  // 初始化颜色数量
  @action public initData = (saleProps: any[], goodsPropPicList: any[], goodsSkuList: any[], forbidCustomSize?: boolean, sizeOptions?: any[]) => {
    if (!this.parent.taobaoFormRef) {
      this.forbidCustomSize = !forbidCustomSize;// 是否禁止自定义尺码
    }

    // 尺码列表
    const sizeObj = saleProps.find((item) => item.propsName.includes('尺码') || item.propsName.includes('尺寸') || item.propsName.includes('身高'));
    this.sizeList = sizeObj?.values.map((item) => ({
      key: nanoid(5),
      name: item.name,
      checked: true,
      editStatus: false,
    }));

    this.sizePropName = sizeObj?.propsName;

    // 如果禁止选择尺码, 尺码选项必须添加平台给定的值
    if (this.forbidCustomSize) {
      this.sizeList = this.sizeList.concat(sizeOptions.map((item) => ({
        key: nanoid(5),
        name: item.value,
        checked: false,
        editStatus: false,
      })));

      this.sizeList = _.uniqBy(this.sizeList, 'name');
    }

    // 颜色列表
    const colorObj = saleProps.find((item) => item.propsName.includes('颜色'));
    this.colorPropName = colorObj?.propsName;
    this.colorList = colorObj?.values.map((item) => {
      const picItem = goodsPropPicList.find((eachPic) => eachPic.properties === `${this.colorPropName}:${item.name}`);
      return {
        key: nanoid(5),
        name: item.name,
        checked: true,
        editStatus: false,
        url: picItem?.url || '',
      };
    });

    // 处理sku列表
    // 如果skuList不为数组，直接置空
    if (!(Array.isArray(goodsSkuList))) {
      this.productSaleSpecGridModel.rows = [];
    }

    this.goodsSkuList = goodsSkuList;

    // 给库存价格预售时间初始值(上市时间给初始值)
    this.goodsSkuList.forEach((item) => {
      item.salePrice = null;// 售价不给初始值了
      item.stockNum = item.stockNum || 100;
      item.skuMarketTime = item.skuMarketTime || moment().format('YYYY-MM-DD');
    });

    // 防止初始状态下修改价格和数量报错
    this.productSaleSpecGridModel.columns = saleSpecColumns(this);
        
    // 填入表格行
    this.productSaleSpecGridModel.rows = this.mapSpecRows(this.goodsSkuList);
    this.calcNumAndPrice();
  };

  // 保存提交校验
  @action public saveValidate = () => {
    // 校验颜色图片
    const checkedColorList = this.colorList.filter((item) => item.checked);
    if (checkedColorList.some((item) => !item.url)) {
      message.warn('请为选中颜色上传图片');
      this.toggleExpandColor(true);
      return false;
    }

    // 保存的话只校验销售信息
    if (!this.goodsSkuList.length) {
      message.warn('请填写商品规格');
      return false;
    }

    // 校验商品规格信息
    if (this.goodsSkuList.some((item) => {
      return !item.salePrice || !item.stockNum;
    })) {
      message.warn('请完善商品规格信息');
      return false;
    }

    // 判断上市时间是否填写(天猫)
    if (!this.parent.taobaoFormRef && this.goodsSkuList.some((item) => {
      return !item.skuMarketTime;
    })) {
      message.warn('请完善商品规格信息');
      return false;
    }
    return true;
  };
}
