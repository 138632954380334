import { Form } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import ImgOrVideoUpload from '../../../../utils/upLoad';
import { ProfitInput, FootPart } from '../../../shelvesBase';
import { AnchorCustom } from '../../components';
import { BaseInfo } from './baseInfo';
import { formLayout } from './common';
import { LogisticsInfo } from './logisticsInfo';
import { PayInfo } from './payInfo';
import { ProductDescription } from './productDesc';
import { SaleInfo } from './saleInfo';

interface ITaobaoStepTwo{
  anchorStore;
  taobaoStore;
  materialStore;
  editorStore;
  singleUploadImgStore;
  mainImgsStore;
}

export const TaobaoStepTwo = observer((props: ITaobaoStepTwo) => {
  const { anchorStore, taobaoStore, materialStore, editorStore, mainImgsStore, singleUploadImgStore } = props;
  const { taobaoFormRef, handleFieldsChange, sizeColorStore } = taobaoStore;
  return (
    <div>
      <Form
        {...formLayout}
        onFieldsChange={handleFieldsChange}
        ref={taobaoFormRef}
        scrollToFirstError
      >
        <AnchorCustom store={anchorStore}/>
        <BaseInfo
          materialStore={materialStore}
          store={taobaoStore}
        />
        <SaleInfo
          singleUploadImgStore={singleUploadImgStore}
          store={taobaoStore}
        />
        <PayInfo/>
        <LogisticsInfo store={taobaoStore}/>
        <ProductDescription
          editorStore={editorStore}
          mainImgsStore={mainImgsStore}
          singleUploadImgStore={singleUploadImgStore}
          store={taobaoStore}
        />
      </Form>
      <FootPart
        save={taobaoStore.save}
        submitInfo={taobaoStore.submit}
      >
        <ProfitInput handleProfitAdd={sizeColorStore.handleSalePriceAddProfit}/>
      </FootPart>
      <ImgOrVideoUpload store={singleUploadImgStore.uploadStoreImg}/>
    </div>
  );
});

