import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Input, Radio, Select, Col, Spin, Row, Popover } from 'antd';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap } from '../../../../shelvesBase';
import type { MaterialStore } from '../../../components';
import { MaterialProps } from '../../../components';
import type { IEachProps } from '../common';
import styles from '../index.less';

const { Item } = Form;
const { Option } = Select;

const propsLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

interface ISuffix{
  materialStore;
}

type IBaseInfo = IEachProps & ISuffix;
 
export const BaseInfo = observer((props: IBaseInfo) => {
  const { materialStore, store } = props;
  const { shopCategoryOptions, brandOptions, brandFetching } = store;
  const { materialDict, isMaterialRequired } = materialStore;
  const { alreadyNum, goodsProps } = props.store;

  return (
    <ContentWrap
      id="baseinfo"
      text="基本信息"
    >
      <Item
        label="商品标题"
        name="goodsName"
        rules={[{ required: true }]}
      >
        <Input
          maxLength={60}
          suffix={`${alreadyNum}/60`}
        />
      </Item>
      <Item label="类目属性">
        <div className={styles.itemWrapper}>
          {
            goodsProps && goodsProps.length > 0 && (
              <Row>
                {/* 材质成分 必填放最前面*/}
                {materialDict && materialDict.length > 0 && isMaterialRequired && (
                  <Col
                    span={12}
                    style={{ marginBottom: '24px' }}
                  >
                    <MaterialPropsComponent
                      store={materialStore}
                    />
                  </Col>
                )}
                {goodsProps && goodsProps.map((item, index) => {
                // ... 在此处不同布局的判断
                  const key = item.id;
                  return (
                    <Col
                      className={item.className || ''}
                      key={key}
                      span={item.colspan || 12}
                    >
                      <Item
                        {...(item.itemLayout || propsLayout)}
                        initialValue={item.type === 'INPUT' ? item.value : (item.type === 'MULTICHECK' ? (item.value ? item.value.split(',') : undefined) : (item.value || undefined))}

                        label={item.tips ? renderLabel({
                          name: item.name,
                          tips: item.tips,
                        }) : item.name}
                        name={key}

                        normalize={(value, prevValue, allValues) => {
                          if (item.type === 'MULTICHECK') {
                            if (typeof value === 'string') {
                              return value.split(',');
                            }
                          }
                          return value;
                        }}
                        rules={[
                          item.rules || {
                            required: item.required,
                            message: `请输入${item.name}`,
                          },
                        ]}
                      >
                        {item.type === 'INPUT' ? (
                          <Input
                            autoComplete="off"
                            className={item.className || ''}
                            id={key}
                            
                            // onChange={(e) => {
                            //   formItemOnChange(e, {
                            //     type: 'input',
                            //     key,
                            //     index,
                            //   });
                            // }}
                            placeholder={item.placeholder || '请输入'}
                          />
                        ) : (
                          item.id !== 'p-20000'
                            ? (
                              <Select
                                allowClear={item.type !== 'INPUT'}
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                mode={item.type === 'MULTICHECK' ? 'multiple' : null}
                                notFoundContent={item.fetching ? <Spin size="small"/> : '无数据'}

                                // onChange={(e) => {
                                //   formItemOnChange(e, {
                                //     type: 'select',
                                //     propsKey: key,
                                //     index,
                                //   });
                                // }}
                                placeholder="请选择"
                                showSearch
                              >
                                {item.checkOptions && item.checkOptions.map((v) => {
                                  return (
                                    <Option
                                      key={v.key}
                                      value={v.key}
                                    >
                                      {v.value}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ) : (
                              <Select
                                allowClear
                                filterOption={false}
                                notFoundContent={brandFetching ? <Spin size="small"/> : '无数据'}
                                onClear={() => {
                                  props.store.handleSearchBranch('');
                                }}
                                onPopupScroll={(e) => {
                                  e.persist();
                                  const { target } = e;
                                  if ((target as any).scrollTop + (target as any).offsetHeight >= (target as any).scrollHeight) {
                                    props.store.handleBrandScroll();
                                  }
                                }}
                                onSearch={(debounce((val) => {
                                  props.store.handleSearchBranch(val);
                                }, 500))}
                                options={brandOptions}
                                placeholder="请选择"
                                showSearch
                              />
                            )
                        )}
                      </Item>
                    </Col>
                  );
                })}
                {/* 材质成分 非必填放最后*/}
                {materialDict && materialDict.length > 0 && !isMaterialRequired && (
                  <Col span={12}>
                    <MaterialPropsComponent store={materialStore}/>
                  </Col>
                )}
              </Row>
            )
          }
        </div>
      </Item>
      <Item
        label="宝贝类型"
        required
      >
        <Radio checked>
          全新
        </Radio>
      </Item>
     
      <Item
        label="采购地"
        required
      >
        <Radio checked>
          中国大陆
        </Radio>
      </Item>
      <Item
        label="店铺分类"
        name="cids"
        wrapperCol={{ span: 5 }}
      >
        <Select
          options={shopCategoryOptions}
          showSearch
        />
      </Item>
    </ContentWrap>
  );
});

// 材质成分组件
const MaterialPropsComponent: React.FC<{ store: MaterialStore; }> = (props) => {
  return (
    <MaterialProps
      inputNumberCustom
      labelStyle={{
        flex: '0 0 25%',
        maxWidth: '25%',
      }}
      selectStyle={{
        flex: '0 0 50%',
        maxWidth: '50%',
      }}
      store={props.store}
    />
  );
};
export const renderLabel = (props) => {
  const { name, tips = []} = props;
  const tipArr = tips;
  return (
    <Popover
      content={(
        <div className={styles.propsTip}>
          {
            tipArr.map((item, index) => {
              let indx = index;
              indx++;
              return (
                <div key={item}>
                  {indx}
                  .
                  {item}
                </div>
              );
            })
          }
        </div>
      )}
    >
      <span style={{ cursor: 'pointer' }}>
        {name}
        <QuestionCircleOutlined/>
      </span>
    </Popover>
  );
};
