import { Spin } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { PublishResult, StepsComponent } from '../shelvesBase';
import styles from './index.less';
import Store from './store';
import { DurRightInfo } from './tianmaoInfo/tmallStepTwo/generateDurabilityInfo';
import { TagsRightInfo } from './tianmaoInfo/tmallStepTwo/generateTagsInfo';

const store = new Store();

@observer
export default class ShelvesNew extends Component {
  render(): JSX.Element {
    const { loading, currentStep, platformType, TAOBAO_STEPS, TIANMAO_STEPS, generateTagsInfoStore, generateDurabilityInfoStore, visibleSubmitResult, submitResult, closeModal, closeResultModal, errorMessage, gmsGoodsId, listingTimeMode, shopName, detailId } = store;
    const steps = platformType === -100 ? TIANMAO_STEPS : TAOBAO_STEPS;
    return (
      <div
        className={styles.contentWrap}
        id="fullModalWrap"
      >
        {
          (currentStep === 1 && generateTagsInfoStore.visible) && (
            <div
              className={styles.imgWrapCanvas}
              id="generateTagsCanvas"
            >
              <TagsRightInfo
                checkList={generateTagsInfoStore.checkList}
              />

            </div>
          )
        }
        {
          (currentStep === 1 && generateDurabilityInfoStore.visible) && (
            <div
              className={styles.imgWrapCanvas}
              id="generateDurabilityInfoCanvas"
            >
              <DurRightInfo
                checkList={generateDurabilityInfoStore.checkList}
              />
            </div>
          )
        }
        {/* 上架内容 */}
        <Spin spinning={loading}>
          <StepsComponent currentStep={currentStep}/>
          {steps[currentStep].content}
        </Spin>
        {/* 铺货结果弹窗 */}
        <PublishResult
          closeModal={closeModal}
          closeResultModal={closeResultModal}
          errorMessage={errorMessage}
          goodsId={detailId}
          listingTimeMode={listingTimeMode}
          platformId={platformType}
          shopName={shopName}
          submitResult={submitResult}
          visibleSubmitResult={visibleSubmitResult}
        />
      </div>
    );
  }
}
