import { Form, Radio, Row, Col } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap, SingleUploadImg } from '../../../../shelvesBase';
import { MainImgs, Editor } from '../../../components';
import type { IEachProps } from '../common';
import styles from '../index.less';

const { Item } = Form;

interface ISuffix{
  editorStore;
  mainImgsStore;
  singleUploadImgStore;
}

type IProductDesc = IEachProps & ISuffix;
 
export const ProductDescription = observer((props: IProductDesc) => {
  const { editorStore, mainImgsStore, singleUploadImgStore, store } = props;
  return (
    <ContentWrap
      id="prouctDesc"
      text="商品描述"
    >
      <Row>
        <Col
          className={styles.colContent}
          span={3}
        >
          <span className={styles.requiredTip}>
            *
          </span>
          商品主图
          <span className={styles.colMao}>
            :
          </span>
        </Col>
        <Col span={21}>
          <MainImgs
            singleUploadImgStore={singleUploadImgStore}
            store={mainImgsStore}
          />
        </Col>
      </Row>
      <Item
        label="商品长图"
        required
      >
        <ProductLongImg
          singleUploadImgStore={singleUploadImgStore}
          store={store}
        />
      </Item>
      <Item
        label="手机图文详情"
      >
        <div className={styles.mobileInfo}>
          由PC端描述自动生成，如需修改请前往淘宝商家后台编辑
        </div>
      </Item>
      <Item
        label="pc端详情"
        required
      >
        <div className={styles.mobileInfo}>
          <Editor
            singleUploadImgStore={singleUploadImgStore}
            store={editorStore}
          />
        </div>
      </Item>
      <Item
        label="上架时间"
        name="listingTimeMode"
        rules={[
          {
            required: true,
            message: '请选择上架时间',
          },
        ]}
      >
        <Radio.Group>
          <Radio value="0">
            立刻上架
          </Radio>
          <Radio value="2">
            放入仓库
          </Radio>
        </Radio.Group>
      </Item>
    </ContentWrap>
  );
});

const ProductLongImg = observer((props: { singleUploadImgStore;store; }) => {
  const { selectImg, deleteImg } = props.singleUploadImgStore;
  const { fullScreenPics } = props.store;
  return (
    <div className={styles.goodsImgWrap}>
      <SingleUploadImg
        deleteImg={() => {
          deleteImg('fullPics');
        }}
        fileList={fullScreenPics}
        imgSize={{
          width: 800,
          height: 1200,
          ratio: null,
        }}
        limitSize={3}
        selectImg={selectImg}
        type="fullPics"
      />
      <div className={styles.imgInfo}>
        为了能更好的展示服装的上身效果,提高前台点击率,建议上传的长图为
        <span className={styles.blue}>
          模特图
        </span>
        ,图片要求尽量是场景图,有故事和画面感。上传符合规范的图片,您的图片会在手机淘宝猜你喜欢,手机搜索等入口拥有优先透出机会。图片不得出现品牌LOGO,不得出现任何形式的边框,不得出现水印,不得出现促销等文字说明,图片尺寸为
        <span className={styles.blue}>
          800*1200
        </span>
        ，大小不超过3M
      </div>
    </div>
  );
});
