import { message, Modal } from 'antd';
import Item from 'antd/lib/list/Item';

import { request, BaseData } from 'egenie-utils';
import { action, toJS, observable } from 'mobx';
import React from 'react';
import { arrayMove } from 'react-sortable-hoc';
import shortid from 'shortid';
import { api, scrollToAnchor } from '../../../../utils';
import { UploadMainImgsStore } from './uploadMainImgs/store';

export class MainImgsStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public styleImgLists: string[];

  @observable public uploadMainImgsStore = new UploadMainImgsStore(this);

  @action public onPictureClick = (src: string): void => {
    const lists = this.uploadMainImgsStore.fileLists;
    try {
      const index = lists.findIndex((item) => item.url === src);
      if (index === -1) {
        lists.push({
          url: src,
          uid: shortid.generate(),
        });
      } else {
        lists.splice(index, 1);
      }
      this.uploadMainImgsStore.fileLists = lists;
    } catch (err) {
      console.log(err);
    }
  };

  // 图片排序
  @action public onSortEnd = ({ oldIndex, newIndex }): void => {
    if (oldIndex !== newIndex) {
      this.uploadMainImgsStore.fileLists = arrayMove(this.uploadMainImgsStore.fileLists, oldIndex, newIndex);
    }
  };

  @action public onClickDelete = (src: string): void => {
    this.uploadMainImgsStore.fileLists = this.uploadMainImgsStore.fileLists.filter((item, index) => {
      return item.url != src;
    });
  };

  @action public clearMainImgs = () => {
    this.uploadMainImgsStore.fileLists = [];
  };

  @action public getMainImgs = () => {
    const arr = [];
    this.uploadMainImgsStore.fileLists.forEach((item) => {
      arr.push(item.url);
    });
    return arr;
  };

  @action public writeBackMainImgs = (mainImgs) => {
    const mainPics = mainImgs?.mainPics || [];
    const arr = [];
    mainPics.forEach((item) => {
      arr.push({
        url: item,
        uid: shortid.generate(),
      });
    });
    this.uploadMainImgsStore.fileLists = arr;
  };
}
