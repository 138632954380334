import { off } from 'process';
import { message, Modal } from 'antd';
import type { FormInstance } from 'antd';
import { request } from 'egenie-utils';
import { action, observable, toJS } from 'mobx';
import React from 'react';
import { api, scrollToAnchor } from '../../../../utils';

export class AnchorStore {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public isValid = 'init';

  @observable public validMatertial = false;// 校验材质成分

  @observable public baseinfoDyamicProps = [];

  @observable public allTitles = {
    baseinfo: {
      title: '基本信息',
      requiredNum: 3,
      hasFilled: 0,
      id: 'baseinfo',
    },
    saleInfo: {
      title: '销售信息',
      requiredNum: 4,
      hasFilled: 0,
      id: 'saleInfo',
    },
    sizeInfo: {
      title: '尺码信息',
      requiredNum: 2,
      hasFilled: 0,
      id: 'sizeInfo',
    },
    goodsDec: {
      title: '商品描述',
      requiredNum: 6,
      hasFilled: 0,
      id: 'goodsDesc',
    },
    logisticsInfo: {
      title: '物流信息',
      requiredNum: 2,
      hasFilled: 0,
      id: 'logisticsInfo',
    },
    afterSaleAndOther: {
      title: '售后及其他',
      requiredNum: 2,
      hasFilled: 0,
      id: 'afterSaleAndOther',
    },
  };

  @observable public allTaobaoTitles = {
    baseinfo: {
      title: '基本信息',
      requiredNum: 6,
      hasFilled: 0,
      id: 'baseinfo',
    },
    saleInfo: {
      title: '销售信息',
      requiredNum: 4,
      hasFilled: 0,
      id: 'saleInfo',
    },
    payinfo: {
      title: '支付信息',
      requiredNum: 3,
      hasFilled: 0,
      id: 'payinfo',
    },
    logisticsinfo: {
      title: '物流信息',
      requiredNum: 1,
      hasFilled: 0,
      id: 'logisticsinfo',
    },
    prouctDesc: {
      title: '商品描述',
      requiredNum: 4,
      hasFilled: 0,
      id: 'prouctDesc',
    },
  };

  @observable public currentLink = '';

  @action public changeAnchor = (currentLink) => {
    this.currentLink = currentLink;
  };

  @action public getTaobaoIncomplete = async() => {
    this.isValid = 'valid';

    // 计算类目属性中必填项的数量
    let requiredNum = 0;
    this.parent.taobaoStore.goodsProps.forEach((item) => {
      if (item.required) {
        requiredNum++;
      }
    });

    this.allTaobaoTitles.baseinfo.requiredNum = requiredNum + 3;
  
    // 材质成分
    if (this.parent.taobaoStore.hasMaterialProps && (this.parent.materialStore.materialLists[0].value !== undefined || this.parent.materialStore.isMaterialRequired)) {
      this.validMatertial = true;
      this.allTaobaoTitles.baseinfo.requiredNum++;
    }
    
    try {
      await this.parent.taobaoStore.taobaoFormRef.current?.validateFields();
    } catch (e) {
      console.log(e);
    } finally {
      const values = this.parent.taobaoStore.taobaoFormRef.current?.getFieldsValue();

      // 基本信息
      let baseInfoFilled = 2;
      const { goodsName } = values;
      if (goodsName) {
        baseInfoFilled++;
      }

      const requiredList = this.parent.taobaoStore.goodsProps.filter((item) => item.required);

      if (requiredList.length > 0) {
        requiredList.forEach((item) => {
          if (values[item.id]) {
            baseInfoFilled++;
          }
        });
      }

      // 材质成分校验
      if (this.validMatertial && this.parent.materialStore.validMaterial()) {
        baseInfoFilled++;
      }

      this.allTaobaoTitles.baseinfo.hasFilled = baseInfoFilled;

      // 销售信息
      const { deliveryTimeType, tbDeliveryTime } = values;

      this.allTaobaoTitles.saleInfo.requiredNum = 4;

      // 大于48小时发货必须填写发货时间
      if (deliveryTimeType === 2) {
        this.allTaobaoTitles.saleInfo.requiredNum++;
      }
      let saleFilled = 0;
      if (tbDeliveryTime) {
        saleFilled++;
      }
      const { salePrice, stockNum } = values;
      if (salePrice) {
        saleFilled++;
      } else {
        message.warn('请填写一口价');
      }

      if (stockNum) {
        saleFilled++;
      } else {
        message.warn('请填写sku数量');
      }

      const checkedColorList = this.parent.taobaoStore.sizeColorStore.colorList.filter((item) => item.checked);
      if (checkedColorList.some((item) => !item.url)) {
        message.warn('请为选中颜色上传图片');
      }
      
      if (checkedColorList.length && !checkedColorList.some((item) => !item.url)) {
        saleFilled++;
      }

      // 判断所有的sku都有售价和数量
      const goodsSkuList = this.parent.taobaoStore.sizeColorStore.goodsSkuList;

      if (goodsSkuList.length && goodsSkuList.every((item) => item.stockNum && item.salePrice)) {
        saleFilled++;
      } else {
        message.warn('请检查商品规格表填写');
      }

      this.allTaobaoTitles.saleInfo.hasFilled = saleFilled;

      // 支付信息
      let payFilled = 3;
      const { payType, stockCountMode, memberDiscount } = values;
      if (!payType) {
        payFilled--;
      }

      if (stockCountMode !== '0' && stockCountMode !== '1') {
        payFilled--;
      }

      if (memberDiscount !== '1' && memberDiscount !== '2') {
        payFilled--;
      }

      this.allTaobaoTitles.payinfo.hasFilled = payFilled;

      // 物流信息
      const { deliveryTemplateId } = values;
      if (deliveryTemplateId) {
        this.allTaobaoTitles.logisticsinfo.hasFilled = 1;
      }

      // 商品描述
      let productDescFilled = 0;
      const { listingTimeMode } = values;

      // 商品主图
      if (this.parent.mainImgsStore.uploadMainImgsStore.fileLists.length > 0) {
        productDescFilled++;
      }

      // 商品长图
      if (this.parent.taobaoStore.fullScreenPics[0]?.url) {
        productDescFilled++;
      }

      // 富文本
      if (this.parent.editorStore.validateContent()) {
        productDescFilled++;
      }

      // 上架时间
      if (listingTimeMode === '0' || listingTimeMode === '1' || listingTimeMode === '2') {
        productDescFilled++;
      }

      this.allTaobaoTitles.prouctDesc.hasFilled = productDescFilled;
    }
  };

  // 天猫
  @action public getIncomplete = () => {
    const allTitles = this.allTitles;
    this.isValid = 'valid';

    // 基本信息
    const baseInfo = this.parent.baseinfoStore.baseinfoFormRef.current.getFieldsValue();
    let baseInfoInComNum = 1;
    if (baseInfo.goodsName) {
      baseInfoInComNum++;
    }
    if (baseInfo.shortTitle) {
      baseInfoInComNum++;
    }
    if (this.baseinfoDyamicProps.length > 0) {
      this.baseinfoDyamicProps.forEach((item) => {
        if (baseInfo[item.id]) {
          baseInfoInComNum++;
        }
      });
    }

    // 材质成分校验
    if (this.parent.baseinfoStore.hasMaterialProps && this.parent.materialStore.validMaterial()) {
      baseInfoInComNum++;
    }
    
    allTitles.baseinfo.hasFilled = baseInfoInComNum;

    // 销售信息
    let saleFilled = 0;
    const values = this.parent.tianmaoStore.saleInfoRef.current?.getFieldsValue();
    const { salePrice, stockNum } = values;

    if (salePrice) {
      saleFilled++;
    } else {
      message.warn('一口价不能为零');
    }

    if (stockNum) {
      saleFilled++;
    } else {
      message.warn('sku数量不能为零');
    }
    const checkedColorList = this.parent.tianmaoStore.sizeColorStore.colorList.filter((item) => item.checked);
    if (checkedColorList.some((item) => !item.url)) {
      message.warn('请为选中颜色上传图片');
    }
    
    if (checkedColorList.length && !checkedColorList.some((item) => !item.url)) {
      saleFilled++;
    }

    // 判断所有的sku都有售价和数量
    const goodsSkuList = this.parent.tianmaoStore.sizeColorStore.goodsSkuList;

    if (goodsSkuList.length && goodsSkuList.every((item) => item.stockNum && item.salePrice)) {
      saleFilled++;
    } else {
      message.warn('请检查商品规格表填写');
    }
    
    allTitles.saleInfo.hasFilled = saleFilled;

    // 销售信息
    // let saleInfoInComNum = 0;
    // const salePropsStore = this.parent.salePropsStore;
    // allTitles.saleInfo.requiredNum = 4;
    // if (salePropsStore.skuArr.length === 1) {
    //   saleInfoInComNum++;
    //   allTitles.saleInfo.requiredNum = 3;
    // }

    // // const skuLength = (salePropsStore.specArr.length < 2 ? 1 : salePropsStore.specArr.length - 1);
    // // allTitles[1].requiredNum += skuLength;
    // if (salePropsStore.colorArr.length > 0) {
    //   saleInfoInComNum++;
    // }
    // if (salePropsStore.skuArr.length > 1 && salePropsStore.sizeArr.length > 0) {
    //   saleInfoInComNum++;
    // }

    // const validSkuInfoLength = salePropsStore.validSkuInfo().length;
    // if (validSkuInfoLength === 0) {
    //   saleInfoInComNum++;
    // }

    // // saleInfoInComNum += (salePropsStore.specArr.length === 0 ? 1 : (salePropsStore.specArr.length - validSkuInfoLength));
    // if (salePropsStore.salePrice) {
    //   saleInfoInComNum++;
    // }
    // allTitles.saleInfo.hasFilled = saleInfoInComNum;

    // 尺码信息
    let sizeInfoInComNum = 0;
    const { sizeMeasureImageLists, sizeMeasureImage } = this.parent.tianmaoStore;
    if (!sizeMeasureImageLists || sizeMeasureImageLists.length === 0) {
      allTitles.sizeInfo.requiredNum = 1;
    }
    if (!sizeMeasureImageLists || sizeMeasureImageLists.length === 0 || sizeMeasureImage) {
      sizeInfoInComNum++;
    }
    const tableArr = this.parent.tableStore.validTableData();
    if (tableArr.length === 0) {
      sizeInfoInComNum++;
    }
    allTitles.sizeInfo.hasFilled = sizeInfoInComNum;

    // 商品描述
    let goodsInfoInComNum = 0;
    const { qualificationPics, verticalImage, whiteBgImage } = this.parent.tianmaoStore;
    if (this.parent.mainImgsStore.uploadMainImgsStore.fileLists.length > 0) {
      goodsInfoInComNum++;
    }
    if (qualificationPics[0].fileList.length > 0 && qualificationPics[0].fileList[0].url) {
      goodsInfoInComNum++;
    }
    if (qualificationPics[1].fileList.length > 0 && qualificationPics[1].fileList[0].url) {
      goodsInfoInComNum++;
    }
    if (verticalImage[0].url) {
      goodsInfoInComNum++;
    }
    if (whiteBgImage[0].url) {
      goodsInfoInComNum++;
    }
    if (this.parent.editorStore.validateContent()) {
      goodsInfoInComNum++;
    }
    allTitles.goodsDec.hasFilled = goodsInfoInComNum;

    // 物流信息 logisticsInfo
    let logisticsInfoInComNum = 0;
    if (this.parent.tianmaoStore.province && this.parent.tianmaoStore.city) {
      logisticsInfoInComNum++;
    }
    if (this.parent.tianmaoStore.extractWay.length > 0) {
      logisticsInfoInComNum++;
    }
    allTitles.logisticsInfo.hasFilled = logisticsInfoInComNum;

    // 售后 afterSaleAndOther
    let afterSaleAndOtherInComNum = 1;
    const { getFieldsValue } = this.parent.tianmaoFormRef.current;
    if (getFieldsValue().rebateRatio) {
      afterSaleAndOtherInComNum++;
    }
    allTitles.afterSaleAndOther.hasFilled = afterSaleAndOtherInComNum;
    console.log('allTitles', allTitles);
    this.allTitles = allTitles;

    // this.scrollTo();
  };

  @action public scrollTo = () => {
    const item = Object.keys(this.allTitles);
    for (let i = 0; i < Object.keys(this.allTitles).length; i++) {
      if (this.allTitles[item[i]].hasFilled < this.allTitles[item[i]].requiredNum) {
        scrollToAnchor(this.allTitles[item[i]].id);
        break;
      }
    }
  };
}
