import React from 'react';
import styles from './index.less';
import type { TaobaoStore } from './store';

interface ITitle{
  text: string;
  id: string;
}

export interface IEachProps{
  store: TaobaoStore;
}

export const formLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

// 标题
export const Title = (props: ITitle) => {
  const { text, id } = props;
  return (
    <div
      className={styles.headTitleWrap}
      id={id}
    >
      <div className={styles.divider}/>
      <span className={styles.headTitle}>
        {text}
      </span>
    </div>
  );
};

