import { Affix, Button, Col, Row } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { CategorySelector } from '../../../shelvesBase';
import styles from './index.less';

export const TaobaoStepOne = observer((props: { taobaoStepOneStore; }) => {
  const { onNext, categoryStore } = props.taobaoStepOneStore;
  const { canNext } = categoryStore;
  return (
    <>
      <div
        className={styles.tmallStepOneWrap}
      >
        <CategorySelector store={categoryStore}/>
      </div>
      <Affix offsetBottom={0}>
        <div>
          <Row>
            <Col
              className={styles.Wrap}
              span={4}
            />
            <Col
              className={styles.footer}
              span={20}
            >
              <Button
                disabled={!canNext}
                onClick={onNext}
                type="primary"
              >
                下一步
              </Button>
            </Col>
          </Row>
        </div>
      </Affix>
    </>
  );
});
