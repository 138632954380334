import { Form, Input, InputNumber, Radio } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import { ContentWrap } from '../../../../shelvesBase';
import { SizeAndColorInfo } from '../../../components';
import { Title } from '../common';
import type { IEachProps } from '../common';
import styles from '../index.less';

const { Item } = Form;

interface ISuffix{
  singleUploadImgStore;
}

type ISaleInfo = IEachProps & ISuffix;

export const SaleInfo = observer((props: ISaleInfo) => {
  const { store, singleUploadImgStore } = props;
  return (
    <ContentWrap
      id="saleInfo"
      text="销售信息"
    >
      {/* 颜色尺码信息 */}
      <SizeAndColorInfo
        singleUploadImgStore={singleUploadImgStore}
        sizeColorStore={store.sizeColorStore}
      />
      <Item
        label="一口价"
        required
        wrapperCol={{ span: 6 }}
      >
        <div className={styles.specFormItem}>
          <Item
            className={styles.innerItem}
            name="salePrice"
            rules={[
              {
                required: true,
                message: '请输入一口价',
              },
            ]}
          >
            <InputNumber
              disabled
              style={{ width: '100%' }}
            />
          </Item>
          <span>
            元
          </span>
        </div>
      </Item>
      <Item
        label="总数量"
        required
        wrapperCol={{ span: 6 }}
      >
        <div className={styles.specFormItem}>
          <Item
            className={styles.innerItem}
            name="stockNum"
            required
          >
            <Input disabled/>
          </Item>
          <span>
            件
          </span>
        </div>
      </Item>
      <DeliveryTime/>
      <Item
        label="商家编码"
        name="goodsCode"
        wrapperCol={{ span: 5 }}
      >
        <Input/>
      </Item>
    </ContentWrap>
  );
});

// 发货时效
const DeliveryTime = observer((props) => {
  return (
    <>
      <Item
        label="发货时效"
        name="deliveryTimeType"
        rules={[
          {
            required: true,
            message: '请选择发货时效',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={3}>
            24小时内发货
          </Radio>
          <Radio value={0}>
            48小时内发货
          </Radio>
          <Radio value={2}>
            大于48小时发货
          </Radio>
        </Radio.Group>
      </Item>
      <Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => (prevValues.deliveryTimeType !== currentValues.deliveryTimeType)}
      >
        {({ getFieldValue }) => {
          return getFieldValue('deliveryTimeType') === 2 ? (
            <Item
              colon={false}
              label=" "
            >
              <div className={styles.tbDeliveryTime}>
                <Item
                  name="tbDeliveryTime"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: '请输入发货时间天数',
                    },
                  ]}
                >
                  <InputNumber
                    max={30}
                    min={3}
                    precision={0}
                  />
                </Item>
                <div className={styles.tbDeliveryTimeTip}>
                  天内发货
                </div>
              </div>
            </Item>
          ) : null;
        }}
      </Item>
    </>
  );
});
