import { Button, Upload } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import styles from './index.less';

const UploadMainImgs = observer((props) => {
  const { store: { fileLists }, singleUploadImgStore } = props;
  console.log(fileLists, 'fileLists');
  
  return (
    <div className={styles.uploadmainImgsWrap}>
      <Button
        className={styles.uploadButton}
        onClick={() => {
          singleUploadImgStore.selectImg('mainPic');
        }}
        size="large"
      >
        + 上传主图
      </Button>
    </div>
  );
});

export default UploadMainImgs;

