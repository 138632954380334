
import { message } from 'antd';
import { request, BaseData } from 'egenie-utils';
import FileSaver from 'file-saver';

import html2canvas from 'html2canvas';
import { observable, action } from 'mobx';
import { api } from '../../../../../utils/api';

class Store {
  constructor(options) {
    this.parent = options;
  }

  @observable public parent;

  @observable public allWashIcons = [];

  @observable public visible;

  @observable public visibleIcons;

  @observable public btnLoading = false;

  @observable public checkedIds = [
    83,
    49,
    25,
    22,
    18,
  ];

  @observable public checkList = [
    {
      createdAt: null,
      creator: null,
      id: 29,
      lastUpdated: null,
      lastUpdater: null,
      remark: '30度水洗',
      url: 'http://egenie.oss-cn-beijing.aliyuncs.com/printImge/100017/20170228135555-28346691-ffae-4b00-b2e2-77fe9130e94c-5B719C9782E09E652DD5D8EAC2D46091.jpg',
      usable: true,
      washIconName: '30度水洗',
    },
    {
      createdAt: 1475164800000,
      creator: 1,
      id: 25,
      lastUpdated: 1475164800000,
      lastUpdater: 1,
      remark: '悬挂晾干',
      url: 'http://egenie.oss-cn-beijing.aliyuncs.com/printCustomImge/100005/20160930102635-cd7d4620-4f7a-439b-95af-0c2b891acb04.bmp',
      usable: true,
      washIconName: '悬挂晾干',
    },
  ]; // 已经选择的标签

  @action public showAllIcons = () => {
    this.visibleIcons = true;
  };

  @action public selectedImg = (e, item) => {
    let checkList = this.checkList;
    const isSelectItem = checkList.find((el) => el.id === item.id);
    if (!isSelectItem) {
      if (checkList.length < 8) {
        checkList.push(item);
      }
    } else {
      checkList = checkList.filter((el) => el.id !== item.id);
    }
    this.checkList = checkList;
  };

  @action public removeItem = (index) => {
    const checkList = this.checkList;
    checkList.splice(index, 1);
    this.checkList = checkList;
  };

  @action public showModal = () => {
    this.parent.generateTagsInfoStore.closeModal();
    this.visible = true;
  };

  @action public closeModal = () => {
    this.visible = false;
  };

  @action public closeIconsModal = () => {
    this.visibleIcons = false;
  };

  // 生成图片
  @action public generatePic = (): void => {
    this.parent.generateTagsInfoStore.generatePic('washIcon', this.checkList, '请选择水洗标签', 'generateDurabilityInfoCanvas', 1);
  };
}

export default Store;
