
import type { FormInstance } from 'antd';
import type { BaseData } from 'egenie-utils';
import { request } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, autorun, runInAction } from 'mobx';
import { api } from '../../../../utils';
import { CategorySelectorStore, dealGoodsProps } from '../../../shelvesBase';
import type { ICategoryProps } from '../../interface';

class Store {
  constructor(options) {
    this.parent = options;

    // 类目变化自动获取类目属性接口
    autorun(() => {
      if (this.categoryStore.canNext) {
        this.parent.categoryName = this.categoryStore.selectTxt;
        const selectedCategoryId = this.categoryStore.selectedValue[this.categoryStore.selectedValue.length - 1];
        this.parent.categoryId = selectedCategoryId;
        
        this.categoryName = this.categoryStore.selectTxt.replace(/>/g, ',');
        this.categoryId = selectedCategoryId;
        this.categoryIds = this.categoryStore.selectedValue.join(',');
        this.getCategoryProps(selectedCategoryId);
      }
    });
  }

  public categoryName = '';

  public categoryId = 0;

  public categoryIds = '';

  @observable public parent;

  @observable public categoryStore = new CategorySelectorStore();// 类目选择store

  @observable public fixedPropsForm: FormInstance; // 固定类目属性Form

  @observable public otherPropsForm: FormInstance; // 其他类目属性Form

  @observable public goodsPropsForm: FormInstance; // 商品属性Form

  @observable public fixedPropsValues = {}; // 固定类目属性Values

  @observable public otherPropsValues = {}; // 其他类目属性Values

  @observable public otherPropsValuesTmp = {}; // 其他类目属性Values

  @observable public goodsPropsValues = {}; // 商品属性Values

  @observable public fixedProps: ICategoryProps[]; // 固定属性 // 品牌和货号

  @observable public otherProps: ICategoryProps[]; // 其余类目属性

  @observable public otherPropsTmp: ICategoryProps[]; // 其余类目属性

  @observable public goodsProps: ICategoryProps[]; // 商品属性 step2中会用到

  @observable public cascadeProps; // 类目属性的级联属性

  @observable public submitloading = false;

  @observable public saveloading = false;

  @observable public brandNameLists = [];

  public hasMaterialProps = false;

  @action public setFixedFormRef = (form) => (this.fixedPropsForm = form);

  @action public setOtherFormRef = (form) => (this.otherPropsForm = form);

  @action public setGoodsFormRef = (form) => (this.goodsPropsForm = form);

  // 根据所选类目 获取产品属性
  @action public getCategoryProps = (categoryId: number) => {
    if (!categoryId) {
      return;
    }
    this.parent.loading = true;
    Object.assign(this, { otherProps: []});
    request<any>({
      url: `${api.getProductPutOnShelfFields}`,
      method: 'post',
      data: {
        gmsGoodsId: Number(this.parent.gmsGoodsId),
        categoryId,
      },
    }).then(action((res) => {
      const allProps = res.data.productProps || [];
      let arrOther = [];
      const arrFix = [];
      allProps.forEach((item, index) => {
        if (item.id === 'prop_20000' || item.id === 'prop_13021751') {
          arrFix.push(item);
          if (item.id === 'prop_20000') {
            this.brandNameLists = item.checkOptions || [];
          }
        } else {
          arrOther.push(item);
        }

        const { goodsCategoryId } = res.data;

        // 补丁代码:类目为连衣裙和衬衫 适用年龄字段为必填
        if ((goodsCategoryId == 100513 || goodsCategoryId == 100515) && item.id === 'prop_20017') {
          item.required = true;
        }

        // 补丁代码:类目为上市季节年份 按照最新年份倒序排列
        if (item.id === 'prop_122216347') {
          item.checkOptions = _.sortBy(item.checkOptions, (o) => Number(o.value.slice(0, 4))).reverse();
        }
      });

      // 类目属性排序
      arrOther = dealGoodsProps(arrOther);

      if (arrFix[0].name !== '品牌') {
        const tmp = arrFix[0];
        arrFix[0] = arrFix[1];
        arrFix[1] = tmp;
      }

      this.otherPropsForm?.resetFields();

      Object.assign(this, {
        cascadeProps: res.data.linkedPropsMap || {},
        fixedProps: arrFix,
        otherProps: this.getLastCategoryProps(arrOther, res.data.linkedPropsMap),
      });

      if (res.data.productMaterialPropsVo) {
        this.hasMaterialProps = true;
        Object.assign(this.parent.materialStore, {
          materialDict: res.data.productMaterialPropsVo?.materialPropOptions || [],
          materialDictNoInput: res.data.productMaterialPropsVo.noNeedContentPropNames || [],
          isMaterialRequired: res.data.productMaterialPropsVo.required,
        });
        const materialPropValus = this.getPropsNeedInput(res.data.productMaterialPropsVo?.materialPropValus || [], res.data.productMaterialPropsVo.noNeedContentPropNames || []);
        this.parent.materialStore.writebackMaterialProps(materialPropValus);
      } else {
        this.hasMaterialProps = false;
        Object.assign(this.parent.materialStore, {
          materialDict: [],
          materialDictNoInput: [],
          isMaterialRequired: false,
        });
      }
    
      this.parent.loading = false;
    }))
      .catch(() => {
        runInAction(() => {
          this.fixedProps = [];
          this.otherProps = [];
          this.cascadeProps = {};
          this.parent.materialStore.materialDict = [];
          this.parent.loading = false;
        });
      });
  };

  @action public getPropsNeedInput = (materialPropValus, noNeedContentPropNames) => {
    materialPropValus && materialPropValus.forEach((item) => {
      if (noNeedContentPropNames.find((el) => el === item.name)) {
        item.needContentNumber = false;
      } else {
        item.needContentNumber = true;
      }
    });
    return materialPropValus;
  };

  // 类目属性统计 查询是否有级联的需要显示
  public getLastCategoryProps = (categoryProps, cascadeProps): any => {
    if (!categoryProps || categoryProps.length === 0) {
      return [];
    }
    const _categoryProps = categoryProps;
    const cascadePropsKeys = Object.keys(cascadeProps);
    const otherPropsValues = {};
    categoryProps.forEach((item, index) => {
      let _index = index;
      const isCascade = cascadePropsKeys.find((el) => el === `${item.id}_${item.value}`);
      if (isCascade) {
        _index++;
        _categoryProps.splice(_index, 0, cascadeProps[`${item.id}_${item.value}`]);
        otherPropsValues[item.id] = {
          preV: '',
          currentV: `${item.value}`,
        };
      }
    });

    this.otherPropsValues = otherPropsValues;
    return _categoryProps;
  };

  // 下一步或者保存
  @action public onSave = async(type): Promise<any> => {
    const { materialStore } = this.parent;

    const materialProps = materialStore.getMaterialLists(type);

    if (type === 'next') {
      await this.fixedPropsForm.validateFields();
      await this.otherPropsForm.validateFields();

      if (materialStore.isMaterialRequired) {
        if (materialProps.length === 0) {
          materialStore.error = true;
          return Promise.reject();
        } else {
          materialStore.error = false;
        }
      } else {
        materialStore.error = false;
      }
      this.submitloading = true;
    } else {
      this.saveloading = true;
    }

    if (this.hasMaterialProps && materialProps.length > 0) {
      if (!materialStore.validMaterial()) {
        return Promise.reject();
      }
    }
    this.parent.loading = true;
    const url = type === 'save' ? api.saveProductPutOnShelf : api.publishProduct;
    const params = {
      gmsGoodsId: this.parent.gmsGoodsId,
      materialProps,
      productProps: { ...this.parent.categoryPropsStore.getCategoryProps() },
      goodsCategoryId: this.categoryId,
      goodsCategoryIds: this.categoryIds,
      goodsCategoryFullName: this.categoryName,
    };
    request<BaseData<any>>({
      url,
      method: 'post',
      data: params,
    }).then((res) => {
      this.parent.loading = false;
      if (type === 'next') {
        this.parent.next();
        this.parent.getTianmaoInfo();
      }
    })
      .catch(() => {
        this.submitloading = false;
        this.saveloading = false;
        this.parent.loading = false;
      });
  };
}

export default Store;
