import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData, PaginationData } from 'egenie-utils';
import { request, EgGridModel } from 'egenie-utils';
import _ from 'lodash';
import { observable, action, toJS, flow } from 'mobx';
import { nanoid } from 'nanoid';
import React from 'react';
import { api, escape2Html, scrollToAnchor } from '../../../../utils';
import { charaRegGlobal, getImageInfo, dealUnlegalImg, dealGoodsProps, calcStrLen, sliceStrLen } from '../../../shelvesBase';
import { SizeColorStore } from '../../components';
import type { IShelfResult } from '../../interface';

// 获取品牌选项
export const getBrandOptions = (page: number, categoryId: number, brandName?: string) => {
  return request<PaginationData>({
    url: '/api/gms/goods/platform/taobao/searchBrand',
    method: 'POST',
    data: {
      categoryId,
      page,
      pageSize: 50,
      brandName,
    },
  });
};

export class TaobaoStore {
  constructor(parent) {
    this.parent = parent;
  }

  @observable public parent;

  @observable public sizeColorStore = new SizeColorStore(this);// 颜色尺码store

  @observable public gmsGoodsId: number;

  @observable public taobaoFormRef = React.createRef<FormInstance>();

  @observable public productTitle = '';// 商品标题

  @observable public goodsProps = [];

  @observable public shopCategoryOptions = [];// 店铺分类options

  @observable public fullScreenPics = [];// 商品长图

  @observable public alreadyNum = 0;// 商品标题已填字数

  @observable public deliveryTemplateLoading = false;// 运费模版loading

  @observable public deliveryTemplatesOptions = [];// 运费模版选项

  @observable public hasMaterialProps = false;// 拥有材质成分属性

  public costPrice = 0;// 成本价

  public brandPage = 1;

  public branchPageTotalCount = 0;
 
  public searchBrandName = '';

  @observable public brandOptions = [];

  @observable public brandFetching = false;

  // 处理表单中值的变化
  @action public handleFieldsChange = (changefileds) => {
    const changeFieldName = changefileds[0].name[0];

    // 商品标题
    if (changeFieldName === 'goodsName') {
      const tempProductTitleLength = calcStrLen(changefileds[0].value);

      if (tempProductTitleLength > 60) {
        this.taobaoFormRef?.current.setFieldsValue({ goodsName: sliceStrLen(changefileds[0].value, 60) });
      }

      this.alreadyNum = tempProductTitleLength > 60 ? 60 : tempProductTitleLength;
    }
  };

  @action public getPropsNeedInput = (materialPropValus, noNeedContentPropNames) => {
    materialPropValus && materialPropValus.forEach((item) => {
      if (noNeedContentPropNames.find((el) => el === item.name)) {
        item.needContentNumber = false;
      } else {
        item.needContentNumber = true;
      }
    });
    return materialPropValus;
  };

  // 品牌选项下拉
  public handleBrandScroll = flow(function* () {
    this.brandPage++;
    if (this.brandPage > this.totalPageCount) {
      return;
    }
    try {
      const res = yield getBrandOptions(this.brandPage, this.parent.taobaoStepOneStore.categoryId, this.searchBrandName);
      const { totalPageCount, list, page } = res.data;
      this.totalPageCount = totalPageCount;
      this.brandPage = page;
      this.brandOptions = this.brandOptions.concat(this.parent.mapOptions(list, 'value', 'key'));
    } catch (e) {
      console.log('查找品牌选项出错', e);
    }
  });

  // 查找品牌选项
  public handleSearchBranch = flow(function* (brandName) {
    this.brandPage = 1;
    this.brandFetching = true;
    this.searchBrandName = brandName;
    try {
      const res = yield getBrandOptions(this.brandPage, this.parent.taobaoStepOneStore.categoryId, brandName);
      this.totalPageCount = res.data?.totalPageCount || 0;
      this.brandOptions = this.parent.mapOptions(res.data?.list, 'value', 'key');
    } catch (e) {
      console.log('查找品牌选项出错', e);
    } finally {
      this.brandFetching = false;
    }
  });

  // 获取表格回显信息
  @action public getTaobaoInfo = (data) => {
    this.parent.next();
    return request<BaseData<any>>({
      method: 'POST',
      url: api.getTaobaoInfo,
      data,
    })
      .then(async(res) => {
        const { productMaterialProps, goodsBase, goodsPic, goodsProps, goodsSkuList, saleProps, goodsPropPicList, gmsGoodsTaobaoExtendsVo, vendorSimple } = res.data;

        const { goodsName, goodsCode } = goodsBase;

        this.brandPage = 1;
        
        let brandOptions;

        try {
          // 类目为品牌 选项分页获取
          const brandRes = await getBrandOptions(this.brandPage, this.parent.taobaoStepOneStore.categoryId);
          brandOptions = this.parent.mapOptions(brandRes.data?.list, 'value', 'key') || [];
        } catch (e) {
          console.log('获取品牌出错', e);
        }

        // 年份季节倒序排列
        goodsProps.forEach((item) => {
          // 补丁代码:类目为上市季节年份 按照最新年份倒序排列
          if (item.id === 'p-122216347') {
            item.checkOptions = _.sortBy(item.checkOptions, (o) => Number(o.value.slice(0, 4))).reverse();
          }

          // 品牌分页获取
          if (item.id === 'p-20000') {
            this.brandOptions = brandOptions;
          }
        });

        // 商品属性排序(必填项放在最前面)
        this.goodsProps = dealGoodsProps(goodsProps);
        
        const { stockCountMode, invoice, deliveryTemplateId, memberDiscount, listingTimeMode, payType, cids, afterSaleService, deliveryTimeType, tbDeliveryTime } = gmsGoodsTaobaoExtendsVo;

        // 处理商品长图
        if (goodsPic.fullScreenPics[0]) {
          try {
            goodsPic.fullScreenPics[0] = await this.parent.dealLongImg(goodsPic.fullScreenPics[0]);
          } catch (e) {
            console.log('加载商品长图出错', e);
          }
        }

        // 商品长图
        const { fullScreenPics } = goodsPic;
        
        this.fullScreenPics = [
          {
            url: fullScreenPics[0],
            key: nanoid(5),
          },
        ];

        // 面料成分
        if (productMaterialProps) {
          this.hasMaterialProps = true;
          Object.assign(this.parent.materialStore, {
            materialDict: productMaterialProps.materialPropOptions || [],
            materialDictNoInput: productMaterialProps.noNeedContentPropNames || [],
            isMaterialRequired: productMaterialProps.required,
          });

          // /回写材质成分
          const materialPropValus = this.getPropsNeedInput(productMaterialProps.materialPropValus || [], productMaterialProps.noNeedContentPropNames || []);
          this.parent.materialStore.writebackMaterialProps(materialPropValus);
        } else {
          this.hasMaterialProps = false;
        }
        
        // 处理颜色图片格式
        try {
          for (let i = 0; i < goodsPropPicList.length; i++) {
            const { Format } = await getImageInfo(goodsPropPicList[i].url);
            goodsPropPicList[i].url = dealUnlegalImg(goodsPropPicList[i].url, Format.value);
          }
        } catch (e) {
          console.log('处理颜色图片出错', e);
        }

        this.sizeColorStore.initData(saleProps, goodsPropPicList, goodsSkuList);

        // 处理主图尺寸
        try {
          for (let i = 0; i < goodsPic.mainPics.length; i++) {
            goodsPic.mainPics[i] = await this.parent.dealMainImg(goodsPic.mainPics[i]);
          }
        } catch (e) {
          console.log('加载主图出错', e);
        }

        // 回写主图信息
        this.parent.mainImgsStore.writeBackMainImgs(goodsPic);
        
        // 回写富文本
        this.parent.editorStore.writeBackEditor(this.parent.gmsGoodsId);

        // 回写表单内容
        this.taobaoFormRef?.current.setFieldsValue({
          goodsName, // 商品名称
          cids, // 店铺分类
          goodsCode, // 商家编码
          stockCountMode: stockCountMode || '1', // 库存计数
          invoice: invoice === '1', // 提供发票
          afterSaleService: afterSaleService || '1', // 售后服务
          deliveryTemplateId: this.deliveryTemplatesOptions[0].value, // 运费模版
          memberDiscount: memberDiscount || '1', // 会员打折
          payType: payType || 'b', // 付款方式
          listingTimeMode: listingTimeMode || '0', // 上架时间
          deliveryTimeType: deliveryTimeType || 0, // 发货时间
          tbDeliveryTime, // 发货天数
        });

        const tempProductTitle = goodsName.replace(charaRegGlobal, 'rr');
        this.alreadyNum = tempProductTitle.length;
      })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 刷新运费模版
  @action public refreshDeliveryTemplate = async() => {
    this.deliveryTemplateLoading = true;
    try {
      await this.parent.getTaobaDeliveryTemplates();
    } catch (e) {
      this.deliveryTemplateLoading = false;
    }
  };

  // 保存
  @action public save = async() => {
    // 保存的话不显示多少项没填
    this.parent.anchorStore.isValid = 'init';

    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('saleInfo');
      return;
    }
    this.parent.loading = true;

    const data = this.assembleData();

    let editorContent;

    try {
      editorContent = await this.parent.editorStore.relaseContent('save');
    } catch (e) {
      console.log(`富文本转换url failed ${e}`);
    }

    data.goodsPicVo.htmlUrl = editorContent?.data || '';

    request<BaseData>({
      url: api.saveTaobaoGoodsSetOnShelf,
      method: 'POST',
      data,
    }).then((res) => {
      message.success(res.data || res.info || '保存成功');
    })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 提交
  @action public submit = async() => {
    if (!this.sizeColorStore.saveValidate()) {
      scrollToAnchor('saleInfo');
      return;
    }

    this.parent.loading = true;
    await this.parent.anchorStore.getTaobaoIncomplete();
    const allTitleKeys = Object.keys(this.parent.anchorStore.allTaobaoTitles);
    
    let saveEnabled = true;
    for (let i = 0; i < allTitleKeys.length; i++) {
      const eachItem = this.parent.anchorStore.allTaobaoTitles[allTitleKeys[i]];
      if (eachItem.hasFilled < eachItem.requiredNum) {
        saveEnabled = false;
        scrollToAnchor(eachItem.id);
        break;
      }
    }
    
    if (!saveEnabled) {
      this.parent.loading = false;
      return;
    }

    const data = this.assembleData();

    data.gmsGoodsTaobaoExtends.groupCode = 'chinese';

    // 富文本
    let editorContent;
    try {
      editorContent = await this.parent.editorStore.relaseContent('save');
    } catch (e) {
      console.log(`富文本转换url failed ${e}`);
    }
    
    data.goodsPicVo.htmlUrl = editorContent?.data || '';

    this.parent.submitResult = 'inProgress';
    this.parent.visibleSubmitResult = true;

    request<BaseData<IShelfResult>>({
      url: api.submitTaobaoGoodsSetOnShelf,
      method: 'POST',
      data,
      timeout: 60000,
    }).then((res) => {
      this.parent.submitResult = 'Successful';
      const { platformId, shopName } = res.data;
      this.parent.detailId = platformId;
      this.parent.shopName = shopName;
    })
      .catch((e) => {
        message.destroy();
        this.parent.submitResult = 'Failed';
        this.parent.errorMessage = escape2Html(e.data?.info || '');
      })
      .finally(() => {
        this.parent.loading = false;
      });
  };

  // 数据组装
  @action public assembleData = () => {
    const values = this.taobaoFormRef.current?.getFieldsValue();

    const { deliveryTemplateId, invoice, listingTimeMode, memberDiscount, payType, stockCountMode, afterSaleService, cids, deliveryTimeType, tbDeliveryTime } = values;

    const gmsGoodsId = this.parent.gmsGoodsId;

    // 类目属性(对象)
    const goodsProps = this.goodsProps.reduce((accu, cur) => {
      return Object.assign(accu, { [cur.id]: values[cur.id]?.toString() });
    }, {});

    // 材质成分
    const materialProps = this.parent.materialStore.getMaterialLists('test');
    const gmsGoodsTaobaoExtends = {
      deliveryTemplateId,
      cids,
      invoice: invoice ? '1' : '0',
      listingTimeMode,
      memberDiscount,
      payType,
      stockCountMode,
      groupCode: '', // hook
      afterSaleService: afterSaleService ? '1' : '0',
      goodsProps,
      materialProps,
      deliveryTimeType,
      tbDeliveryTime,
    };

    const { goodsName, salePrice, stockNum, goodsCode } = values;

    // 商品类目名称和id
    const goodsCategoryFullName = this.parent.taobaoStepOneStore.categoryName;
    const goodsCategoryId = this.parent.taobaoStepOneStore.categoryId;
    const goodsCategoryIds = this.parent.taobaoStepOneStore.categoryIds;

    // 商品基础信息
    const goodsBaseVo = {
      goodsCategoryFullName,
      goodsCategoryIds,
      goodsCategoryId,
      goodsName,
      salePrice,
      stockNum,
      goodsCode,
    };
  
    const mainPics = this.parent.mainImgsStore.uploadMainImgsStore.fileLists.map((item) => item.url);// 主图

    const fullScreenPics = this.fullScreenPics.map((item) => item.url).filter(Boolean);// 长图

    const goodsPicVo = {
      mainPics,
      fullScreenPics,
      htmlUrl: '', // 富文本url 先hook住
    };

    const checkedColorList = toJS(this.sizeColorStore.colorList).filter((item) => item.checked);
    const { colorPropName } = this.sizeColorStore;
    const goodsPropPicVos = checkedColorList.map((item) => ({
      url: item.url,
      properties: `${colorPropName}:${ item.name}`,
      smallPicUrl: '',
    }));

    const goodsSkuVos = toJS(this.sizeColorStore.goodsSkuList);

    const saveData = {
      gmsGoodsId,
      gmsGoodsTaobaoExtends,
      goodsBaseVo,
      goodsPicVo,
      goodsPropPicVos,
      goodsSkuVos,
    };

    return saveData;
  };

  @action public prev = () => {
    this.parent.prev();

    // 重置
    this.goodsProps = [];
  };
}
